/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import cookie from "react-cookies";
import { connect } from "react-redux";
import {
  appId,
  apiUrl,
  apiUrlV2,
  deliveryId,
  timThumpUrl,
} from "../Helpers/Config";
import {
  getReferenceID,
  stripslashes,
  showPriceValue,
  callImage,
  showLoader,
  hideLoader,
  showCustomAlert,
  showCartLst,
  showAlert,
  removePromoCkValue,
} from "../Helpers/SettingHelper";
import noimage from "../../common/images/no-img-product.png";

import { GET_FAVOURITELIST, GET_PRODUCT_LIST } from "../../actions";
var Parser = require("html-react-parser");
var qs = require("qs");

class FavouriteproductList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedNavigation: "",
      selectedslugType: "",
      productSubcatlist: [],
      productCommon: [],
      productDetails: [],
      filter_Tag: [],
      currentSubcat: "all",
    };
    this.props.getFavouriteproductslist();
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.productSubcatlist !== nextProps.productSubcatlist) {
      this.setState(
        { productSubcatlist: nextProps.productSubcatlist },
        function () {
          this.productsubcatlist();
        }
      );
    }
  }

  productsubcatlist() {
    var subcatlistArr = this.props.productSubcatlist;
    if (Object.keys(subcatlistArr).length > 0) {
      const productDetails = (
        <div className="innerproduct">
          <ul className="products-list-ulmain">
            {this.productList(subcatlistArr)}
          </ul>
        </div>
      );

      this.setState({ productDetails: productDetails }, function () {
        setTimeout(function () {
          hideLoader("productlist-main-div", "class");
        }, 500);
      });
      return productDetails;
    }
  }

  chkProStockCls(proSlug, Stock) {
    var searchProVal = this.props.productState.searchProVal;
    var actClstxt = searchProVal === proSlug ? " active" : "";

    var returnText =
      "products-single-li no-stock-product " + proSlug + actClstxt;
    if (Stock > 0) {
      returnText = "products-single-li " + proSlug + actClstxt;
    }

    return returnText;
  }

  /* Products List */
  productList(list) {
    var imageSource = this.props.productCommon.product_image_source;
    var pro_cate_slug,
      pro_subcate_slug = "";
    const listProduct = list.map((productDetail, index) => {
      var description =
        productDetail.product_short_description !== null &&
        productDetail.product_short_description !== ""
          ? Parser(stripslashes(productDetail.product_short_description))
          : "";

      var prodivId = "proIndex-" + productDetail.product_primary_id;
      let filter = this.checkFilterTag(productDetail.product_tag);
      if (filter === 1) {
        return (
          <li
            className={this.chkProStockCls(
              productDetail.product_slug,
              productDetail.product_stock
            )}
            id={prodivId}
            key={index}
          >
            <div className="products-image-div">
              {productDetail.product_thumbnail !== "" ? (
                <img
                  src={imageSource + "/" + productDetail.product_thumbnail}
                />
              ) : (
                <img src={noimage} />
              )}
            </div>

            <div className="product-info-div">
              <div className="product-title-maindiv">
                <div className="product-title">
                  <h3>
                    {productDetail.product_alias !== ""
                      ? stripslashes(productDetail.product_alias)
                      : stripslashes(productDetail.product_name)}
                  </h3>
                </div>
              </div>
              <div className="product-info-merge">
                <div className="product-tag-list"></div>

                <div className="product-short-description">
                  <p>{description !== "" ? description : Parser("&nbsp;")}</p>
                </div>

                <div className="product-price">
                  <h3>{showPriceValue(productDetail.product_price)}</h3>
                </div>

                <div className="products-ordernow-action">
                  {productDetail.product_stock > 0 ||
                  productDetail.product_stock === null ? (
                    productDetail.product_type === "1" ? (
                      <a
                        className="button order_nowdiv smiple_product_lk disbl_href_action"
                        href="/"
                        onClick={this.addToCartSimple.bind(
                          this,
                          productDetail,
                          "initial"
                        )}
                      >
                        Add to Cart
                      </a>
                    ) : (
                      <Link
                        to={
                          "/products/" +
                          pro_cate_slug +
                          "/" +
                          pro_subcate_slug +
                          "/" +
                          productDetail.product_slug
                        }
                        title="Product Details"
                        className="button order_nowdiv compo_product_lk"
                      >
                        Select
                      </Link>
                    )
                  ) : (
                    <a
                      className="button order_nowdiv disabled disbl_href_action"
                      href="/"
                    >
                      Sold Out
                    </a>
                  )}
                  <div className="addcart_row addcart_done_maindiv">
                    <div className="addcart-row-child">
                      <div className="qty_bx">
                        <span
                          className="qty_minus"
                          onClick={this.proQtyAction.bind(
                            this,
                            productDetail.product_primary_id,
                            "decr"
                          )}
                        >
                          -
                        </span>
                        <input
                          type="text"
                          className="proqty_input"
                          readOnly
                          value="1"
                        />
                        <span
                          className="qty_plus"
                          onClick={this.proQtyAction.bind(
                            this,
                            productDetail.product_primary_id,
                            "incr"
                          )}
                        >
                          +
                        </span>
                      </div>
                      <button
                        className="btn btn_black"
                        onClick={this.addToCartSimple.bind(
                          this,
                          productDetail,
                          "done"
                        )}
                      >
                        Done
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        );
      }
    });

    return listProduct;
  }

  checkFilterTag(productFilter) {
    var current = this;
    if (
      this.state.filter_Tag !== "" &&
      typeof this.state.filter_Tag !== undefined &&
      typeof this.state.filter_Tag !== "undefined" &&
      this.state.filter_Tag.length > 0
    ) {
      if (Object.keys(productFilter).length > 0) {
        let tagAvil = 0;
        productFilter.map(function (item) {
          if (current.state.filter_Tag.indexOf(item.tag_id) >= 0) {
            tagAvil++;
          }
        });
        if (tagAvil > 0) {
          return 1;
        } else {
          return 0;
        }
      }
    } else {
      return 1;
    }
  }

  proQtyAction(indxFlg, actionFlg) {
    var proqtyInput = $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val();
    proqtyInput = parseInt(proqtyInput);
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }
    $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val(proqtyInput);
  }

  /* add to cart */
  addToCartSimple(productDetail, actionFlg, event) {
    event.preventDefault();
    var IndexFlg = productDetail.product_primary_id;

    if (actionFlg === "initial") {
      $("#proIndex-" + IndexFlg).addClass("active");
      $("#proIndex-" + IndexFlg)
        .find(".smiple_product_lk")
        .hide();
      $("#proIndex-" + IndexFlg)
        .find(".addcart_done_maindiv")
        .show();
      return false;
    } else {
      showLoader("proIndex-" + IndexFlg, "Idtext");
      var availabilityId = cookie.load("defaultAvilablityId");
      /*var availabilityId = deliveryId;*/
      var availabilityName =
        availabilityId === deliveryId ? "Delivery" : "Pickup";
      var isAddonProduct = "No";
      var productId = productDetail.product_id;
      var customerId =
        typeof cookie.load("UserId") === "undefined"
          ? ""
          : cookie.load("UserId");
      var proqtyQty = $("#proIndex-" + IndexFlg)
        .find(".proqty_input")
        .val();

      var postObject = {};
      postObject = {
        app_id: appId,
        product_id: productId,
        product_qty: proqtyQty,
        product_type: 1,
        availability_id: availabilityId,
        availability_name: availabilityName,
        isAddonProduct: isAddonProduct,
        reference_id: customerId === "" ? getReferenceID() : "",
        customer_id: customerId,
      };

      Axios.post(
        apiUrlV2 + "cart/simpleCartInsert",
        qs.stringify(postObject)
      ).then((res) => {
        $("#proIndex-" + IndexFlg).removeClass("active");
        hideLoader("proIndex-" + IndexFlg, "Idtext");
        hideLoader("productlist-main-div", "class");
        $("#proIndex-" + IndexFlg)
          .find(".addcart_done_maindiv")
          .hide();
        $("#proIndex-" + IndexFlg)
          .find(".smiple_product_lk")
          .show();
        if (res.data.status === "ok") {
          this.props.sateValChange("cartflg", "yes");
          removePromoCkValue();
          /* showCustomAlert("success", "Great choice! Item added to your cart."); */
          /*showCartLst();*/
          this.handleShowAlertFun(
            "success",
            "Great choice! Item added to your cart."
          );
        } else if (res.data.status === "error") {
          var errMsgtxt =
            res.data.message !== ""
              ? res.data.message
              : "Sorry! Products can`t add your cart.";
          showCustomAlert("error", errMsgtxt);
          this.handleShowAlertFun("Error", errMsgtxt);
        }

        return false;
      });
    }
  }

  viewProDetail(productDetail, event) {
    event.preventDefault();
    var productSlug = productDetail.product_slug;
    if (productSlug !== "") {
      $("#proIndex-" + productDetail.product_primary_id).addClass("active");
      this.props.sateValChange("view_pro_data", productSlug);
    }
    return false;
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  render() {
    return (
      <div className="productlist-main-div favourite-product-list">
        <div className="innerproduct_row">{this.state.productDetails}</div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var tempArr = Array();
  var favouriteproductlist = Array();
  var productCmn = Array();
  if (Object.keys(state.favouriteproductlist).length > 0) {
    var tempArr = state.favouriteproductlist;
    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        favouriteproductlist = tempArr[0].result_set;
        productCmn = tempArr[0].common;
      }
    }
  }

  return {
    productSubcatlist: favouriteproductlist,
    productCommon: productCmn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFavouriteproductslist: () => {
      dispatch({ type: GET_FAVOURITELIST });
    },
  };
};

export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(FavouriteproductList);
