/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import cookie from "react-cookies";
import { connect } from "react-redux";

import {
  appId,
  tagImageUrl,
  apiUrl,
  apiUrlV2,
  deliveryId,
  currencysymbol,
} from "../Helpers/Config";
import {
  getReferenceID,
  stripslashes,
  showPriceValue,
  showLoader,
  hideLoader,
  showCustomAlert,
  showAlert,
  removePromoCkValue,
} from "../Helpers/SettingHelper";
import noimage from "../../common/images/no-img-product.png";

var Parser = require("html-react-parser");
var qs = require("qs");

class ProductDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  calculatePrice() {
    var totalPrice = parseFloat(
      $(
        "#comboinsidecombo_" + this.props.comboProductID + " .product_price"
      ).val()
    );
    var comboPrice = 0;
    $(
      "#comboinsidecombo_" +
        this.props.comboProductID +
        " .combo_combo_menu_set"
    ).map(function () {
      var $_thisN = $(this);
      if ($_thisN.find(".selected").length > 0) {
        $_thisN.find(".selected").map(function () {
          if (parseFloat($(this).attr("data-price")) > 0) {
            comboPrice =
              parseFloat(comboPrice) + parseFloat($(this).attr("data-price"));
          } else {
            comboPrice = parseFloat(comboPrice);
          }
        });
      }
    });
    totalPrice = parseFloat(totalPrice) + parseFloat(comboPrice);

    $(
      "#comboinsidecombo_" + this.props.comboProductID + " .combo_combo_price"
    ).html(totalPrice.toFixed(2));

    $(
      "#comboinsidecombo_" +
        this.props.comboProductID +
        " .product_combo_add_price"
    ).val(comboPrice.toFixed(2));
  }

  productDetailsMain() {
    var proDetails = this.props.productdetail;
    var comboProductID = this.props.comboProductID;
    if (Object.keys(proDetails).length > 0) {
      var desc =
        proDetails.product_long_description !== "" &&
        proDetails.product_long_description !== null
          ? stripslashes(proDetails.product_long_description)
          : "";
      var comboLgth = proDetails.set_menu_component
        ? proDetails.set_menu_component.length
        : 0;
      var ModifLgth = proDetails.modifiers ? proDetails.modifiers.length : 0;
      var isCombo =
        proDetails.product_type === "2" && comboLgth > 0 ? "Yes" : "No";
      var isModifier =
        proDetails.product_type === "4" && ModifLgth > 0 ? "Yes" : "No";
      var isSimple =
        proDetails.product_type === "1" && comboLgth === 0 && ModifLgth === 0
          ? "Yes"
          : "No";
      return (
        <div
          key={proDetails.product_primary_id}
          id={"product_" + comboProductID}
        >
          <div className="combo-in-combo-pro-details">
            <h3>
              {" "}
              {proDetails.product_alias !== ""
                ? stripslashes(proDetails.product_alias)
                : stripslashes(proDetails.product_name)}{" "}
            </h3>
            {desc !== "" && <p>{Parser(desc)}</p>}
          </div>

          <div className="">
            {isCombo === "Yes" ? this.comboProDetails(proDetails) : ""}
          </div>
        </div>
      );
    } else if (Object.keys(proDetails).length === 0) {
      return (
        <div className="product-detail-empty">
          Sorry, Invalid Product Detail.{" "}
        </div>
      );
    } else {
      return "";
    }
  }

  comboProDetails(proDetailArr) {
    var comboArr =
      proDetailArr.set_menu_component !== null &&
      proDetailArr.set_menu_component !== ""
        ? proDetailArr.set_menu_component
        : Array();

    if (Object.keys(comboArr).length > 0) {
      var minMaxSelect = parseInt(proDetailArr.product_apply_minmax_select);
      var compoTotalPrice = proDetailArr.product_price;
      /* minMaxSelect === 0 &&  */
      return (
        <>
          <div className="combo-customize-parent">
            {this.singleComboDet(comboArr)}
            <div className="compo_acc_active main_combo_in_combo_div combo-customize-child ">
              <div className="compo_pro_acc compo_acc_action">
                <h6>Note</h6>
              </div>

              <div className="compo_acc_innerdiv">
                <div className="text-box">
                  <textarea
                    placeholder="You can enter your special remark in the section..."
                    className="combo_special_notes"
                    maxLength="30"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="combo-total-price">
            <p>
              <strong>Value</strong>
              <sup>S$</sup>
              <span className="combo_combo_price">{compoTotalPrice}</span>
            </p>
            <input
              type="hidden"
              value={compoTotalPrice}
              className="product_price"
            />
            <input
              type="hidden"
              value="0"
              className="product_combo_add_price"
            />
          </div>
          <div className="topping-button">
            <button
              onClick={this.chooseCombo.bind(this, proDetailArr.product_id)}
            >
              Choose
            </button>
            <div className="cart-error-msg alert alert_danger combo_in_comb_error_msg"></div>
          </div>
        </>
      );
    } else {
      return "";
    }
  }

  singleComboDet(compoProDetailArr) {
    var comboProductID1 = this.props.comboProductID;
    if (Object.keys(compoProDetailArr).length > 0) {
      return compoProDetailArr.map((item, index) => {
        return (
          <div
            key={index}
            className="compo_acc_active combo_combo_menu_set main_combo_in_combo_div combo-customize-child"
            id={"combosec_" + comboProductID1 + "_" + item.menu_component_id}
            data-comobdetail={
              item.menu_component_id + "~" + item.menu_component_name
            }
            data-min={item.menu_component_min_select}
            data-max={item.menu_component_max_select}
          >
            <div className="compo_pro_acc compo_acc_action">
              <h6>{stripslashes(item.menu_component_name)}</h6>
            </div>
            <div className="compo_acc_innerdiv">
              <div className="form-group">
                <div
                  className="error_combo_in_combo error"
                  style={{ display: "none" }}
                >
                  {" "}
                  Please select the min {
                    item.menu_component_min_select
                  } items.{" "}
                </div>
                {this.showSingleComboOptions(item)}
              </div>
            </div>
          </div>
        );
      });
    }
  }
  showSingleComboOptions(splProducts) {
    var compoListArr =
      splProducts.product_details !== null && splProducts.product_details !== ""
        ? splProducts.product_details
        : Array();
    var comboProPrice = 0;
    var ismdfrProCount = 0;
    var comboPropriceAply = "";
    var menuComponentApplyPrice =
      splProducts.menu_component_apply_price !== ""
        ? splProducts.menu_component_apply_price
        : 0;
    var menuSetID = splProducts.menu_set_component_id;
    var comboProductID = this.props.comboProductID;
    if (compoListArr.length > 0) {
      if (splProducts.menu_component_single_selection === "1") {
        return (
          <div data-widthimage={1}>
            <ul
              className="combo_product_list combo-customize-child-list"
              id={"combo_set" + comboProductID + "_" + menuSetID}
            >
              {splProducts.product_details.length > 0 &&
                splProducts.product_details.map((item, index) => {
                  let productDetail = item[0];
                  comboProPrice = productDetail.product_price;
                  ismdfrProCount =
                    productDetail.modifiers !== null &&
                    productDetail.modifiers !== ""
                      ? Object.keys(productDetail.modifiers).length
                      : 0;
                  comboPropriceAply =
                    parseFloat(comboProPrice) > 0 &&
                    parseInt(menuComponentApplyPrice) === 1 &&
                    parseInt(ismdfrProCount) === 0
                      ? comboProPrice
                      : 0;
                  return (
                    <li
                      key={index}
                      id={
                        "combo_pro" +
                        comboProductID +
                        "_" +
                        menuSetID +
                        "_" +
                        productDetail.product_primary_id
                      }
                      name={
                        "combo_pro" +
                        comboProductID +
                        "_" +
                        menuSetID +
                        "_" +
                        productDetail.product_primary_id
                      }
                      onClick={this.selectCombo.bind(
                        this,
                        menuSetID,
                        productDetail.product_primary_id,
                        productDetail.product_set_outof_stock,
                        productDetail.product_out_of_stock,
                        "li"
                      )}
                      /*  onClick={this.selectCombo.bind(
                        this,
                        menuSetID,
                        productDetail.product_primary_id,
                        "li"
                      )} */
                      className={
                        productDetail.product_set_outof_stock === "1" ||
                        productDetail.product_out_of_stock === "Yes"
                          ? "inner-outof-sotck"
                          : ""
                      }
                      data-price={comboPropriceAply > 0 ? comboProPrice : 0}
                      data-product={
                        productDetail.product_id +
                        "~" +
                        productDetail.product_name +
                        "~" +
                        productDetail.product_sku +
                        "~" +
                        productDetail.product_alias
                      }
                      data-qty="1"
                    >
                      <div>
                        {productDetail.product_thumbnail !== "" ? (
                          <img
                            src={
                              this.props.productcommon.image_source +
                              "/" +
                              productDetail.product_thumbnail
                            }
                          />
                        ) : (
                          <img src={noimage} />
                        )}
                      </div>
                      <div className="toppings-title">
                        <h5>
                          {productDetail.product_alias !== ""
                            ? stripslashes(productDetail.product_alias)
                            : stripslashes(productDetail.product_name)}
                        </h5>
                        {(productDetail.product_set_outof_stock === "1" ||
                          productDetail.product_out_of_stock === "Yes") && (
                          <span className="un-available">(Unavailable)</span>
                        )}
                        {comboPropriceAply > 0 && (
                          <div className="product-price">
                            <h3>{showPriceValue(comboPropriceAply)}</h3>
                          </div>
                        )}
                      </div>
                      <div className="combo-in-combo-tooltips">
                        <p>
                          {productDetail.product_alias !== ""
                            ? stripslashes(productDetail.product_alias)
                            : stripslashes(productDetail.product_name)}
                        </p>
                        {(productDetail.product_set_outof_stock === "1" ||
                          productDetail.product_out_of_stock === "Yes") && (
                          <span>(Unavailable)</span>
                        )}
                        {comboPropriceAply > 0 && (
                          <div>
                            <h4>{showPriceValue(comboPropriceAply)}</h4>
                          </div>
                        )}
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        );
      } else {
        return (
          <div data-widthimage={0}>
            <ul
              className="combo_product_list combo-customize-child-radio"
              id={"combo_set" + comboProductID + "_" + menuSetID}
            >
              {splProducts.product_details.length > 0 &&
                splProducts.product_details.map((item, index) => {
                  let productDetail = item[0];
                  comboProPrice = productDetail.product_price;
                  ismdfrProCount =
                    productDetail.modifiers !== null &&
                    productDetail.modifiers !== ""
                      ? Object.keys(productDetail.modifiers).length
                      : 0;
                  comboPropriceAply =
                    parseFloat(comboProPrice) > 0 &&
                    parseInt(menuComponentApplyPrice) === 1 &&
                    parseInt(ismdfrProCount) === 0
                      ? comboProPrice
                      : 0;
                  return (
                    <li
                      key={index}
                      id={
                        "combo_pro" +
                        comboProductID +
                        "_" +
                        menuSetID +
                        "_" +
                        productDetail.product_primary_id
                      }
                      name={
                        "combo_pro" +
                        comboProductID +
                        "_" +
                        menuSetID +
                        "_" +
                        productDetail.product_primary_id
                      }
                      data-price={comboPropriceAply > 0 ? comboProPrice : 0}
                      data-product={
                        productDetail.product_id +
                        "~" +
                        productDetail.product_name +
                        "~" +
                        productDetail.product_sku +
                        "~" +
                        productDetail.product_alias
                      }
                      data-qty="1"
                    >
                      <div className="crust-title">
                        <input
                          type={
                            parseInt(splProducts.menu_component_max_select) > 1
                              ? "checkbox"
                              : "radio"
                          }
                          disabled={
                            productDetail.product_set_outof_stock === "1" ||
                            productDetail.product_out_of_stock === "Yes"
                              ? true
                              : false
                          }
                          name={
                            parseInt(splProducts.menu_component_max_select) > 1
                              ? "combo_pro" +
                                comboProductID +
                                "_" +
                                menuSetID +
                                "_" +
                                index
                              : "combo_pro" + comboProductID + "_" + menuSetID
                          }
                          id={
                            "comboprocheck" +
                            comboProductID +
                            "_" +
                            menuSetID +
                            "_" +
                            productDetail.product_id
                          }
                          /* onChange={this.selectComboradio.bind(
                            this,
                            menuSetID,
                            productDetail.product_primary_id,
                            productDetail.product_id,
                            "checkbox"
                          )} */
                          onChange={this.selectComboradio.bind(
                            this,
                            menuSetID,
                            productDetail.product_primary_id,
                            productDetail.product_id,
                            productDetail.product_set_outof_stock,
                            productDetail.product_out_of_stock,
                            "checkbox"
                          )}
                        />
                        <label
                          htmlFor={
                            "comboprocheck" +
                            comboProductID +
                            "_" +
                            menuSetID +
                            "_" +
                            productDetail.product_id
                          }
                        >
                          {productDetail.product_alias !== ""
                            ? stripslashes(productDetail.product_alias)
                            : stripslashes(productDetail.product_name)}
                          {(productDetail.product_set_outof_stock === "1" ||
                            productDetail.product_out_of_stock === "Yes") && (
                            <span className="un-available">(Unavailable)</span>
                          )}
                          {comboPropriceAply > 0 && (
                            <span className="product-price">
                              <h3>{showPriceValue(comboPropriceAply)}</h3>
                            </span>
                          )}
                        </label>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        );
      }
    }
  }
  /* selectCombo(menuSetID, productID) {
    var comboProductID = this.props.comboProductID;

    if (
      $(
        "#combo_pro" +
          comboProductID +
          "_" +
          menuSetID +
          "_" +
          productID +
          ".selected"
      ).length > 0
    ) {
      $(
        "#combo_pro" + comboProductID + "_" + menuSetID + "_" + productID
      ).removeClass("selected");
      if (
        $("#combosec_" + comboProductID + "_" + menuSetID).find("selected")
          .length === 0
      ) {
        $("#combosec_" + comboProductID + "_" + menuSetID).addClass("selected");
      }
    } else {
      var comboAttr = $(
        "#comboinsidecombo_" +
          this.props.comboProductID +
          " #combosec_" +
          comboProductID +
          "_" +
          menuSetID
      );
      var min = parseInt(comboAttr.attr("data-min"));
      var max = parseInt(comboAttr.attr("data-max"));

       if (min >= 0) {
      } else {
        $("#combo_set" + menuSetID + " li").removeClass("selected");
      } 
      if (max > 0) {
        if (
          parseInt(
            $("#combosec_" + comboProductID + "_" + menuSetID + " .selected")
              .length
          ) +
            1 >
          max
        ) {
          return false;
        }
      }
      console.log(
        max,
        "maxmaxmaxmax",
        parseInt(
          $("#combosec_" + comboProductID + "_" + menuSetID + " .selected")
            .length
        ) + 1,
        "#combo_pro" + comboProductID + "_" + menuSetID + "_" + productID,
        $("#combo_pro" + comboProductID + "_" + menuSetID + "_" + productID)
          .length
      );
      $("#combosec_" + comboProductID + "_" + menuSetID).addClass("selected");
      $(
        "#combo_pro" + comboProductID + "_" + menuSetID + "_" + productID
      ).addClass("selected");
    }

    this.calculatePrice();
  } */
  selectCombo(
    menuSetID,
    productID,
    product_set_outof_stock,
    product_out_of_stock,
    typeofselect
  ) {
    var comboProductID = this.props.comboProductID;

    if (product_set_outof_stock === "0" && product_out_of_stock === "No") {
      if (
        $(
          "#combo_pro" +
            comboProductID +
            "_" +
            menuSetID +
            "_" +
            productID +
            ".selected"
        ).length > 0
      ) {
        $(
          "#combo_pro" + comboProductID + "_" + menuSetID + "_" + productID
        ).removeClass("selected");
        if (
          $("#combosec_" + comboProductID + "_" + menuSetID).find("selected")
            .length === 0
        ) {
          $("#combosec_" + comboProductID + "_" + menuSetID).addClass(
            "selected"
          );
        }
      } else {
        var comboAttr = $(
          "#comboinsidecombo_" +
            this.props.comboProductID +
            " #combosec_" +
            comboProductID +
            "_" +
            menuSetID
        );
        var min = parseInt(comboAttr.attr("data-min"));
        var max = parseInt(comboAttr.attr("data-max"));
        if (max === 1) {
          $(
            "#combo_set" + comboProductID + "_" + menuSetID + " li"
          ).removeClass("selected");
        }
        if (max > 0) {
          if (
            parseInt(
              $("#combosec_" + comboProductID + "_" + menuSetID + " .selected")
                .length
            ) +
              1 >
            max
          ) {
            return false;
          }
        }

        $("#combosec_" + comboProductID + "_" + menuSetID).addClass("selected");
        $(
          "#combo_pro" + comboProductID + "_" + menuSetID + "_" + productID
        ).addClass("selected");
      }

      this.calculatePrice();
    }
  }

  /* selectComboradio(menuSetID, productID, product_id) {
    var comboProductID = this.props.comboProductID;
    var maxSel = parseInt(
      $("#combosec_" + comboProductID + "_" + menuSetID).attr("data-max")
    );
    if (maxSel <= 1) {
      console.log(comboProductID, menuSetID, "menuSetID");
      $("#combo_set" + comboProductID + "_" + menuSetID + " li").removeClass(
        "selected"
      );
    }
    if (
      $(
        "#combo_pro" +
          comboProductID +
          "_" +
          menuSetID +
          "_" +
          productID +
          ".selected"
      ).length > 0
    ) {
      $(
        "#combo_pro" + comboProductID + "_" + menuSetID + "_" + productID
      ).removeClass("selected");
    } else {
      if (
        $("#combo_set" + comboProductID + "_" + menuSetID + " li.selected")
          .length >= maxSel
      ) {
        $(
          "#comboprocheck" + comboProductID + "_" + menuSetID + "_" + product_id
        ).prop("checked", false);
        return false;
      }
      $(
        "#combo_pro" + comboProductID + "_" + menuSetID + "_" + productID
      ).addClass("selected");
    }
    $("#combosec_" + comboProductID + "_" + menuSetID).addClass("selected");
    $(
      "#combosec_" + comboProductID + "_" + menuSetID + " .error_combo_in_combo"
    ).hide();
    this.calculatePrice();
  } */

  selectComboradio(
    menuSetID,
    productID,
    product_id,
    product_set_outof_stock,
    product_out_of_stock
  ) {
    if (product_set_outof_stock === "0" && product_out_of_stock === "No") {
      var comboProductID = this.props.comboProductID;
      var maxSel = parseInt(
        $("#combosec_" + comboProductID + "_" + menuSetID).attr("data-max")
      );
      if (maxSel <= 1) {
        console.log(comboProductID, menuSetID, "menuSetID");
        $("#combo_set" + comboProductID + "_" + menuSetID + " li").removeClass(
          "selected"
        );
      }
      if (
        $(
          "#combo_pro" +
            comboProductID +
            "_" +
            menuSetID +
            "_" +
            productID +
            ".selected"
        ).length > 0
      ) {
        $(
          "#combo_pro" + comboProductID + "_" + menuSetID + "_" + productID
        ).removeClass("selected");
      } else {
        if (
          $("#combo_set" + comboProductID + "_" + menuSetID + " li.selected")
            .length >= maxSel
        ) {
          $(
            "#comboprocheck" +
              comboProductID +
              "_" +
              menuSetID +
              "_" +
              product_id
          ).prop("checked", false);
          return false;
        }
        $(
          "#combo_pro" + comboProductID + "_" + menuSetID + "_" + productID
        ).addClass("selected");
      }
      $("#combosec_" + comboProductID + "_" + menuSetID).addClass("selected");
      $(
        "#combosec_" +
          comboProductID +
          "_" +
          menuSetID +
          " .error_combo_in_combo"
      ).hide();
      this.calculatePrice();
    }
  }

  chooseCombo(product_id) {
    var comboProductID = this.props.comboProductID;
    var error = 0;
    $("#comboinsidecombo_" + comboProductID + " .main_combo_in_combo_div").map(
      function () {
        var min = $(this).attr("data-min");
        if (
          $(this).find(".combo_product_list li.selected").length < parseInt(min)
        ) {
          $(this).find(".error_combo_in_combo").show();
          error++;
        } else {
          $(this).find(".error_combo_in_combo").hide();
        }
      }
    );
    if (error === 0) {
      $("#comboinsidecombo_" + comboProductID + " .combo_in_comb_error_msg")
        .html("")
        .hide();
      $("body").removeClass("combo-in-combo");
      $("#comboinsidecombo_" + comboProductID).modal("hide");
      var request = comboProductID + "_" + product_id;
      var comboProductIDSplit = comboProductID.split("_");
      var product_combo_add_price = $(
        "#comboinsidecombo_" + comboProductID + " .product_combo_add_price"
      ).val();

      $("#proIndex-" + comboProductIDSplit[0] + "-" + comboProductIDSplit[1])
        .find(".product-price")
        .find("span")
        .show()
        .html("(<sup>S$</sup>" + product_combo_add_price + ")");

      $(
        "#proIndex-" + comboProductIDSplit[0] + "-" + comboProductIDSplit[1]
      ).attr("data-comboaddprice", product_combo_add_price);
      this.props.sateValChange("complete_combo", request);
      console.log("aaaaaa");
      $("html, body").animate({
        scrollTop: $(".compo_update_div").offset().top - 190,
      });
    } else {
      $("#comboinsidecombo_" + comboProductID + " .combo_in_comb_error_msg")
        .html("Sorry!. Product Detail was not valid.")
        .show();
      setTimeout(function () {
        $("#comboinsidecombo_" + comboProductID + " .combo_in_comb_error_msg")
          .html("")
          .hide();
      }, 3000);
    }
  }
  closeCombo() {
    $("body").removeClass("combo-in-combo");
  }

  componentDidUpdate() {}

  render() {
    return (
      <div
        className="modal fade commom-modal-topcls combo-customize-combo-main-div"
        id={"comboinsidecombo_" + this.props.comboProductID}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body">
              <div className="common-modal-head">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                  onClick={this.closeCombo.bind(this)}
                >
                  X
                </button>
              </div>
              <div className="modal-detail-box">
                {this.productDetailsMain()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateTopProps, mapDispatchToProps)(ProductDetail);
