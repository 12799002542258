/* eslint-disable */

/* if (location.protocol !== "https:") {
  var locationULR = location.href.replace("www.", "");
  location.href = locationULR.replace("http://", "https://www.");
} else if (location.protocol === "https:") {
  var locationULR = location.href;
  if (locationULR.indexOf("www.") < 0) {
    location.href = "https://www." + locationULR.replace("https://", "");
  }
} */
import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { getStore } from "./store";
import TagManager from "react-gtm-module";

import "./common/css/font-awesome.min.css";
import "./common/css/bootstrap.min.css";
import "./common/css/custom.css";
import "./common/css/responsive.css";
import "./common/css/slick.css";

import Home from "./components/Home/Home";
import Products from "./components/Products/Products";
import ProductDetail from "./components/Products/ProductDetail";
import Favouriteproducts from "./components/Products/Favouriteproducts";
import Checkout from "./components/Checkout/Checkout";
import Thankyou from "./components/Checkout/Thankyou";
import Process from "./components/Checkout/Process";
import Cancel from "./components/Checkout/Cancel";

import Pages from "./components/Pages/Pages";
import Faq from "./components/Pages/Faq";
import ContactUs from "./components/Pages/ContactUs";
import Outlets from "./components/Pages/Outlets";
import Myaccount from "./components/Myaccount/Myaccount";
import Myvoucher from "./components/Myaccount/Myvoucher";
import Placeorder from "./components/Checkout/Placeorder";
import Failed from "./components/Checkout/Failed";

import Orders from "./components/Myaccount/Orders";
import Mypromotions from "./components/Myaccount/Mypromotions";
import Rewards from "./components/Myaccount/Rewards";
import Account from "./components/Account/Account";
import Resetpassword from "./components/Account/Resetpassword";
import Logout from "./components/Myaccount/Logout";
import Refpage from "./components/Layout/Refpage";
import Page404 from "./Page404";
import Redirect from "./Redirect";
const store = getStore();
TagManager.initialize({ gtmId: "UA-195155645-1" });
TagManager.initialize({ gtmId: "G-HZMPHK4X1X" });
TagManager.initialize({ gtmId: "AW-10853291817" });
render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/redirect" component={Redirect} />
        <Route
          path={"/products/:slugType/:slugValue/:proValue"}
          component={ProductDetail}
        />
        <Route path={"/products/:slugType/:slugValue"} component={Products} />
        <Route path="/products" component={Products} />
        <Route path="/favourite" component={Favouriteproducts} />
        <Route path="/checkout" component={Checkout} />
        <Route path="/thankyou/:orderId" component={Thankyou} />
        <Route path="/process" component={Process} />
        <Route path="/cancel/:referanceID" component={Cancel} />
        <Route path="/placeorder" component={Placeorder} />
        <Route path="/failed" component={Failed} />

        <Route path="/page/:page_slug" component={Pages} />
        <Route path="/our-story" component={Pages} />
        <Route path="/christmas-feast" component={Pages} />
        <Route path="/privacy-policy" component={Pages} />
        <Route path="/terms-and-conditions" component={Pages} />
        <Route path="/faq" component={Faq} />
        <Route path="/contact-us" component={ContactUs} />
        <Route path="/our-outlets" component={Outlets} />
        <Route path="/rewards" component={Rewards} />
        <Route path="/mypromotions" component={Mypromotions} />
        <Route path="/voucher" component={Myvoucher} />
        <Route path="/myorders" component={Orders} />
        <Route path="/myaccount" component={Myaccount} />
        <Route path="/account/activation/:activationKey" component={Account} />
        <Route
          path="/account/resetpassword/:resetKey"
          component={Resetpassword}
        />
        <Route path="/logout" component={Logout} />
        <Route
          path={"/refpage/:slugtext/:category/:subcategory"}
          component={Refpage}
        />
        <Route path={"/refpage/:slugtext"} component={Refpage} />
        <Route component={Page404} />
      </Switch>
    </Router>
  </Provider>,

  document.getElementById("root")
);
