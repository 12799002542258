/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import update from "immutability-helper";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Slider from "react-slick";
import cookie from "react-cookies";
import { bindActionCreators } from "redux";
import Changepassword from "./Changepassword";
import { GET_ACTIVITYCOUNT, GET_CHANGEPASSWORD } from "../../actions";
import { appId } from "../Helpers/Config";
import { showAlert, showLoader, hideLoader } from "../Helpers/SettingHelper";
var qs = require("qs");
class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: this.props.pageName,
      deliveryTakeaway_orders: "",
      fieldschpassword: {
        oldpassword: "",
        newpassword: "",
        confirmpassword: "",
      },
    };
    const inputKeys = ["deliveryTakeaway_orders"];
    this.props.getActivityCount(JSON.stringify(inputKeys));
    this.changepassword = this.changepassword.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    /*activity count -start */
    if (Object.keys(nextProps.activitycountNew).length > 0) {
      if (nextProps.activitycountNew !== this.props.activitycountNew) {
        if (
          nextProps.activitycountNew[0].status &&
          nextProps.activitycountNew[0].result_set
        ) {
          this.setState({
            deliveryTakeaway_orders:
              nextProps.activitycountNew[0].result_set.deliveryTakeaway_orders,
          });
        }
      }
    } else {
      this.setState({
        order_all: 0,
        overall_orders: 0,
        catering_orders: 0,
        deliveryTakeaway_orders: 0,
        reservation_orders: 0,
      });
    }

    if (nextProps.changepassword !== this.props.changepassword) {
      $(".old_password").val("");
      $(".new_password").val("");
      $(".confirm_password").val("");
      hideLoader("change-pass-sbmt", "class");
      if (nextProps.changepassword[0].status === "ok") {
        showAlert("Success", "Password changed successfully!");
      } else {
        if (nextProps.changepassword[0].form_error) {
          showAlert("Error", nextProps.changepassword[0].form_error);
        } else {
          showAlert("Error", nextProps.changepassword[0].message);
        }
      }
      window.$.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }
  }

  fieldChange = (field, value) => {
    this.setState(
      update(this.state, { fieldschpassword: { [field]: { $set: value } } })
    );
  };

  /* Change Password */
  changepassword() {
    var old_password = $(".old_password").val();
    var new_password = $(".new_password").val();
    var confirm_password = $(".confirm_password").val();
    var postObject = {
      app_id: appId,
      type: "web",
      oldpassword: old_password,
      password: new_password,
      confirmpassword: confirm_password,
      refrence: cookie.load("UserId"),
    };
    showLoader("change-pass-sbmt", "class");
    this.props.getChangePassword(qs.stringify(postObject));
  }

  render() {
    var initialSlide = 0;
    if (this.state.activePage === "myorders") {
      initialSlide = 1;
    } else if (this.state.activePage === "rewards") {
      initialSlide = 2;
    } else if (this.state.activePage === "mypromotions") {
      initialSlide = 3;
    }
    var settingsMyAcc = {
      infinite: false,
      slidesToShow: 4,
      initialSlide: initialSlide,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 1191,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: false,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
          },
        },
      ],
    };
    return (
      <>
        <div className="mbacc_mslidersec mbacc_mslider ">
          <Slider {...settingsMyAcc}>
            <div
              className={
                this.state.activePage === "myaccount"
                  ? "mbacc_mslide active"
                  : "mbacc_mslide"
              }
            >
              <Link to="/myaccount" title="My Account">
                <span>Account Details</span>
              </Link>
            </div>
            <div
              className={
                this.state.activePage === "myorders"
                  ? "mbacc_mslide active"
                  : "mbacc_mslide"
              }
            >
              <Link to="/myorders" title="My Orders">
                <span>
                  Orders{" "}
                  {parseFloat(this.state.deliveryTakeaway_orders) > 0 && (
                    <strong className="count_num_t">
                      {this.state.deliveryTakeaway_orders}
                    </strong>
                  )}
                </span>
              </Link>
            </div>
            {/* <div
              className={
                this.state.activePage === "rewards"
                  ? "mbacc_mslide active"
                  : "mbacc_mslide"
              }
            >
              <Link to="/rewards" title="My Rewards">
                <span>Rewards</span>
              </Link>
            </div> */}
            <div
              className={
                this.state.activePage === "mypromotions"
                  ? "mbacc_mslide active"
                  : "mbacc_mslide"
              }
            >
              <Link to="/mypromotions" title="My Promotions">
                <span>Promotions</span>
              </Link>
            </div>
            <li className={this.state.activePage === "voucher" ? "active" : ""}>
              <Link to="/voucher" title="Vouchers">
                <span>Vouchers</span>
              </Link>
            </li>
            <div className="mbacc_mslide">
              <a href="#change-password-popup" className="open-popup-link">
                Change Password
              </a>
            </div>
            <div className="mbacc_mslide">
              <a href="/logout">Logout</a>
            </div>
          </Slider>
        </div>
        <div className="accmenu_sec">
          <div className="setting_menu_list">
            <ul>
              <li
                className={
                  this.state.activePage === "myaccount" ? "active" : ""
                }
              >
                <Link to="/myaccount" title="My Account">
                  <span>Account Informations</span>
                </Link>
              </li>
              <li
                className={this.state.activePage === "myorders" ? "active" : ""}
              >
                <Link to="/myorders" title="My Orders">
                  <span>
                    My Orders{" "}
                    {parseFloat(this.state.deliveryTakeaway_orders) > 0 && (
                      <strong className="count_num_t">
                        {this.state.deliveryTakeaway_orders}
                      </strong>
                    )}
                  </span>
                </Link>
              </li>
              {/*  <li
                className={this.state.activePage === "rewards" ? "active" : ""}
              >
                <Link to="/rewards" title="My Rewards">
                  <span>Rewards</span>
                </Link>
              </li> */}
              <li
                className={
                  this.state.activePage === "mypromotions" ? "active" : ""
                }
              >
                <Link to="/mypromotions" title="My Promotions">
                  <span>Promotions</span>
                </Link>
              </li>
              <li
                className={this.state.activePage === "voucher" ? "active" : ""}
              >
                <Link to="/voucher" title="Vouchers">
                  <span>Vouchers</span>
                </Link>
              </li>
              <li>
                <a href="#change-password-popup" className="open-popup-link">
                  Change Password
                </a>
              </li>
              <li>
                <a href="/logout">Logout</a>
              </li>
            </ul>
          </div>
        </div>
        <div
          id="change-password-popup"
          className="white-popup mfp-hide popup_sec changepw_popup"
        >
          <div className="pouup_in">
            <div id="form-msg"></div>
            <h3 className="title1 text-center">Change Password</h3>
            <Changepassword
              fields={this.state.fieldschpassword}
              onChange={this.fieldChange}
              onValid={this.changepassword}
              onInvalid={() => console.log("Form invalid!")}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activitycountNew: state.activitycount,
    changepassword: state.changepassword,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getActivityCount: (getObject) => {
      dispatch({ type: GET_ACTIVITYCOUNT, getObject });
    },
    getChangePassword: (formPayload) => {
      dispatch({ type: GET_CHANGEPASSWORD, formPayload });
    },
  };
};

Sidebar.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Sidebar)
);
