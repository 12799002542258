/* eslint-disable */
import React, { Component } from "react";
import { validated } from "react-custom-validation";
import validator from "validator";
const isEmpty = (value) => (value === "" ? "This field is required." : null);

function validationConfig(props) {
  const { email, password } = props.fields;

  return {
    fields: ["email", "password"],

    validations: {
      email: [[isEmpty, email]],
      password: [[isEmpty, password]],
    },
  };
}

class Login extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { fields, onChange, onValid, onInvalid, $field, $validation } =
      this.props;
    let errMsgEmail, errMsgPassword;

    if ($validation.email.error.reason !== undefined) {
      errMsgEmail = $validation.email.show && (
        <span className="error">{$validation.email.error.reason}</span>
      );
    }
    if ($validation.password.error.reason !== undefined) {
      errMsgPassword = $validation.password.show && (
        <span className="error">{$validation.password.error.reason}</span>
      );
    }

    return (
      <div className="popup-body">
        <span className="spn-error"></span>
        <div className="form-group">
          <div className="focus-out">
            <label>Email Address</label>
            <input
              type="text"
              className="form-control input-focus"
              value={fields.email}
              {...$field("email", (e) => onChange("email", e.target.value))}
            />
            {errMsgEmail}
          </div>
        </div>
        <div className="form-group">
          <div className="focus-out">
            <label>Password</label>

            <input
              type="password"
              className="form-control input-focus"
              value={fields.password}
              {...$field("password", (e) =>
                onChange("password", e.target.value)
              )}
            />
            {errMsgPassword}
          </div>
        </div>
        <div className="form-group">
          <div className="login_pop_sub">
            <button
              className="btn btn_black btn_minwid login_submit"
              onClick={(e) => {
                e.preventDefault();
                this.props.$submit(onValid, onInvalid);
              }}
            >
              {" "}
              Submit{" "}
            </button>
          </div>
        </div>

        <div className="form-group">
          <div className="controls two-links">
            <a href="#forgot-password-popup" className="open-popup-link">
              Forgot Password ?
            </a>{" "}
          </div>
        </div>
      </div>
    );
  }
}
Login = validated(validationConfig)(Login);

export default Login;
