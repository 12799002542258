/* eslint-disable */
import React, { Component } from "react";
import {
  appId,
  apiUrl,
  deliveryId,
  apiUrlV2,
  defaultOutletID,
} from "../../Helpers/Config";
import { stripslashes } from "../../Helpers/SettingHelper";
import { Link } from "react-router-dom";
import axios from "axios";
import _ from "underscore";
var Parser = require("html-react-parser");
import cookie from "react-cookies";
export default class MenuNavigationmob extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuList: [],
      subCategory: [],
    };
  }

  componentDidMount() {
    var availabilityId =
      cookie.load("defaultAvilablityId") !== "" &&
      typeof cookie.load("defaultAvilablityId") !== undefined &&
      typeof cookie.load("defaultAvilablityId") !== "undefined"
        ? cookie.load("defaultAvilablityId")
        : deliveryId;
    var orderOutletId =
      cookie.load("orderOutletId") === undefined ||
      cookie.load("orderOutletId") === "null" ||
      cookie.load("orderOutletId") == ""
        ? defaultOutletID
        : cookie.load("orderOutletId");
    axios
      .get(
        apiUrlV2 +
          "products/getMenuNavigation?app_id=" +
          appId +
          "&availability=" +
          availabilityId +
          "&outletId=" +
          orderOutletId +
          "&source_type=ecommerce"
      )
      .then((res) => {
        if (res.data.status == "ok") {
          if (res.data.result_set !== null) {
            this.setState({ menuList: res.data.result_set });
            cookie.save(
              "firstNavigation",
              res.data.result_set[0].pro_cate_slug,
              {
                path: "/",
              }
            );
          }
        }
      });
  }
  endSubMenuItems(submenu, categorySlug) {
    return submenu.map((loaddata, index) => (
      <li key={index}>
        {" "}
        <Link
          to={"/products/" + categorySlug + "/" + loaddata.pro_subcate_slug}
          title={stripslashes(loaddata.pro_subcate_name)}
          onClick={(e) => {
            $("#close_mobile_menu").trigger("click");
          }}
        >
          {stripslashes(loaddata.pro_subcate_name)}{" "}
        </Link>
      </li>
    ));

    /*return submenu.map((loaddata, index) => <li key={index} > <Link to={"/products/category/" + categorySlug + "/" + loaddata.pro_subcate_slug} title={stripslashes(loaddata
			.pro_subcate_name)} >{stripslashes(loaddata.pro_subcate_name)} </Link></li >);*/
  }

  /* submenu listing */
  showSubMenu(isSubMenu, objects, categorySlug) {
    if (isSubMenu == "Yes") {
      var chunkValues = _.chunk(objects.sub_result_set, 4);
      const listingItems = chunkValues.map((submenu, index) => {
        return (
          <ul className="hcategory_submenu" key={index}>
            {this.endSubMenuItems(submenu, categorySlug)}{" "}
          </ul>
        );
      });
      return listingItems;
    }
  }

  render() {
    var currenturl = window.location.href;
    const mainMenu = this.state.menuList.map((loaddata, index) => {
      if (loaddata.menu_type == "main") {
        var subcategorySlug = "";
        if (Object.keys(loaddata.subcat_list_arr).length > 0) {
          if (
            loaddata.subcat_list_arr.sub_result_set !== "" &&
            typeof loaddata.subcat_list_arr.sub_result_set !== undefined &&
            typeof loaddata.subcat_list_arr.sub_result_set !== "undefined"
          ) {
            if (loaddata.subcat_list_arr.sub_result_set.length > 0) {
              subcategorySlug =
                loaddata.subcat_list_arr.sub_result_set[0].pro_subcate_slug;
            }
          }
        }

        return (
          <li
            key={index}
            className={
              currenturl.includes(loaddata.pro_cate_slug) ? "active" : ""
            }
          >
            {" "}
            <Link
              to={"/products/" + loaddata.pro_cate_slug + "/" + subcategorySlug}
              title={loaddata.menu_custom_title}
              onClick={(e) => {
                e.preventDefault();
                this.props.history.push(
                  "/products/" + loaddata.pro_cate_slug + "/" + subcategorySlug
                );
                $("#close_mobile_menu").trigger("click");
              }}
            >
              {stripslashes(loaddata.menu_custom_title)}{" "}
            </Link>{" "}
            {/* this.showSubMenu(
              loaddata.is_sub_list,
              loaddata.subcat_list_arr,
              loaddata.pro_cate_slug
            ) */}{" "}
          </li>
        );
      } else if (loaddata.menu_type == "sub") {
        return (
          <li
            key={index}
            className={
              currenturl.includes(loaddata.pro_cate_slug) ? "active" : ""
            }
          >
            {" "}
            <Link
              to={"/products/subcategory/" + loaddata.pro_subcate_slug}
              title={stripslashes(loaddata.menu_custom_title)}
            >
              {" "}
              {loaddata.menu_custom_title}{" "}
            </Link>{" "}
          </li>
        );
      }
    });
    return mainMenu;
  }
}
