/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { appId, apiUrl } from "../Helpers/Config";
import { removePromoCkValue, showAlert } from "../Helpers/SettingHelper";
import cookie from "react-cookies";
var qs = require("qs");
class Process extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.checkPaymentStatus();
  }

  checkPaymentStatus() {
    var reference_number = "";
    var messageRe =
      "Order Order Placed Success-" +
      cookie.load("UserId") +
      "-" +
      cookie.load("reference_number") +
      "-" +
      localStorage.getItem("UserId");
    alert(messageRe);
    if (
      cookie.load("reference_number") !== "" &&
      typeof cookie.load("reference_number") !== undefined &&
      typeof cookie.load("reference_number") !== "undefined"
    ) {
      /*  reference_number = cookie.load("reference_number");
    } else {
      var referencenumberlocal = localStorage.getItem("reference_number");
      if (
        referencenumberlocal !== "" &&
        typeof referencenumberlocal !== undefined &&
        typeof referencenumberlocal !== "undefined"
      ) {
        reference_number = referencenumberlocal;
        if (
          localStorage.getItem("UserId") !== "" &&
          typeof localStorage.getItem("UserId") !== undefined &&
          typeof localStorage.getItem("UserId") !== "undefined"
        ) {
          cookie.save("UserId", localStorage.getItem("UserId"), { path: "/" });
        }

        if (
          localStorage.getItem("UserEmail") !== "" &&
          typeof localStorage.getItem("UserEmail") !== undefined &&
          typeof localStorage.getItem("UserEmail") !== "undefined"
        ) {
          cookie.save("UserEmail", localStorage.getItem("UsUserEmailerId"), {
            path: "/",
          });
        }

        if (
          localStorage.getItem("UserFname") !== "" &&
          typeof localStorage.getItem("UserFname") !== undefined &&
          typeof localStorage.getItem("UserFname") !== "undefined"
        ) {
          cookie.save("UserFname", localStorage.getItem("UserFname"), {
            path: "/",
          });
        }

        if (
          localStorage.getItem("UserLname") !== "" &&
          typeof localStorage.getItem("UserLname") !== undefined &&
          typeof localStorage.getItem("UserLname") !== "undefined"
        ) {
          cookie.save("UserLname", localStorage.getItem("UserLname"), {
            path: "/",
          });
        }

        if (
          localStorage.getItem("UserMobile") !== "" &&
          typeof localStorage.getItem("UserMobile") !== undefined &&
          typeof localStorage.getItem("UserMobile") !== "undefined"
        ) {
          cookie.save("UserMobile", localStorage.getItem("UserMobile"), {
            path: "/",
          });
        }

        if (
          localStorage.getItem("UserBirthdate") !== "" &&
          typeof localStorage.getItem("UserBirthdate") !== undefined &&
          typeof localStorage.getItem("UserBirthdate") !== "undefined"
        ) {
          cookie.save("UserBirthdate", localStorage.getItem("UserBirthdate"), {
            path: "/",
          });
        }

        if (
          localStorage.getItem("UserDefaultAddress") !== "" &&
          typeof localStorage.getItem("UserDefaultAddress") !== undefined &&
          typeof localStorage.getItem("UserDefaultAddress") !== "undefined"
        ) {
          cookie.save(
            "UserDefaultAddress",
            localStorage.getItem("UserDefaultAddress"),
            { path: "/" }
          );
        }

        if (
          localStorage.getItem("UserDefaultUnitOne") !== "" &&
          typeof localStorage.getItem("UserDefaultUnitOne") !== undefined &&
          typeof localStorage.getItem("UserDefaultUnitOne") !== "undefined"
        ) {
          cookie.save(
            "UserDefaultUnitOne",
            localStorage.getItem("UserDefaultUnitOne"),
            { path: "/" }
          );
        }

        if (
          localStorage.getItem("UserDefaultUnitTwo") !== "" &&
          typeof localStorage.getItem("UserDefaultUnitTwo") !== undefined &&
          typeof localStorage.getItem("UserDefaultUnitTwo") !== "undefined"
        ) {
          cookie.save(
            "UserDefaultUnitTwo",
            localStorage.getItem("UserDefaultUnitTwo"),
            { path: "/" }
          );
        }

        if (
          localStorage.getItem("UserDefaultPostalCode") !== "" &&
          typeof localStorage.getItem("UserDefaultPostalCode") !== undefined &&
          typeof localStorage.getItem("UserDefaultPostalCode") !== "undefined"
        ) {
          cookie.save(
            "UserDefaultPostalCode",
            localStorage.getItem("UserDefaultPostalCode"),
            { path: "/" }
          );
        }
      }
    } */
      reference_number = cookie.load("reference_number");
      /*  if (reference_number !== "") { */
      var cabtureObjects = {
        app_id: appId,
        req_reference_number: reference_number,
      };
      axios
        .post(
          apiUrl + "cybersource/checkingPaymentStatus",
          qs.stringify(cabtureObjects)
        )
        .then((captureRes) => {
          if (captureRes.data.status === "ok") {
            cookie.remove("reference_number", { path: "/" });
            localStorage.removeItem("reference_number");
            this.showSuccessPage(captureRes.data.common.local_order_no);
            return;
          } else if (captureRes.data.status === "error") {
            if (captureRes.data.retry === "yes") {
              this.checkPaymentStatus();
            } else {
              var omsMsgTxt =
                captureRes.data.message != ""
                  ? captureRes.data.message
                  : "Your order was not successful.";
              showAlert("Error", omsMsgTxt);
              $.magnificPopup.open({
                items: {
                  src: ".alert_popup",
                },
                type: "inline",
              });
              this.props.history.push("/");
              return false;
            }
          } else {
          }
        })
        .catch((error) => {
          showAlert("Error", error);
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
          this.props.history.push("/");
          return false;
        });
    } else {
      this.props.history.push("/");
      return false;
    }
  }

  /* sucess page */
  showSuccessPage(localOrderNo) {
    this.deleteOrderCookie();
    cookie.save("ChkOrderid", localOrderNo, { path: "/" });
    const { history } = this.props;
    $.magnificPopup.close();
    history.push("/thankyou/" + localOrderNo);
  }

  /* this function used to  delete all cookie values */
  deleteOrderCookie() {
    removePromoCkValue();

    cookie.remove("orderPaymentMode", { path: "/" });
    cookie.remove("reference_number", { path: "/" });
    cookie.remove("orderTableNo", { path: "/" });
    cookie.remove("product_remarks", { path: "/" });
    /* cookie.remove("orderOutletName"); */
    //cookie.remove('orderOutletId');
    cookie.remove("carttotalitems", { path: "/" });
    cookie.remove("cartsubtotal", { path: "/" });
    cookie.remove("cartid", { path: "/" });

    /* Delivery avilablity */
    /*  cookie.remove("deliveryDate");
    cookie.remove("deliveryTime"); */
    cookie.remove("unitNoOne", { path: "/" });
    cookie.remove("unitNoTwo", { path: "/" });
    cookie.remove("firstNavigation", { path: "/" });

    cookie.remove("promotion_id", { path: "/" });
    cookie.remove("promotion_applied", { path: "/" });
    cookie.remove("promotion_code", { path: "/" });
    cookie.remove("promotion_delivery_charge_applied", { path: "/" });
    cookie.remove("promotion_amount", { path: "/" });
    cookie.remove("promotion_category", { path: "/" });
    cookie.remove("prmo_type", { path: "/" });
    cookie.remove("promoOutlet", { path: "/" });
    cookie.remove("promoType", { path: "/" });
    cookie.remove("promoPercentage", { path: "/" });

    /*Remove voucher*/
    cookie.remove("voucher_applied", { path: "/" });
    cookie.remove("voucher_code", { path: "/" });
    cookie.remove("voucher_amount", { path: "/" });

    cookie.remove("points_redeemed", { path: "/" });
    cookie.remove("points_used", { path: "/" });
    cookie.remove("points_amount", { path: "/" });
    cookie.remove("prmo_type", { path: "/" });
  }
  render() {
    return (
      <div className="checkout-main-div">
        {/* Header start */}
        <Header
          cartTriggerFlg={this.state.cartTriggerFlg}
          sateValChange={this.sateValChange}
        />
        {/* Header End */}

        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <p>Placing Order</p>
          </div>
        </div>

        <div id="dvLoading"></div>

        {/* Footer section */}
        <Footer />
        {/* Donate popup - end */}
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateTopProps, mapDispatchToProps)(Process);
