/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import cookie from "react-cookies";
import axios from "axios";
import {
  stripslashes,
  getCalculatedAmount,
  showLoader,
  hideLoader,
  getOrderDateTime,
  getPromoCkValue,
  removePromoCkValue,
  resetCrtStyle,
  showAlert,
  cnvrtStr,
} from "../Helpers/SettingHelper";
import { deliveryId, cateringId, appId, apiUrl } from "../Helpers/Config";
import OrderAdvancedDatetimeSlot from "./OrderAdvancedDatetimeSlot";
import crossImg from "../../common/images/cross1.png";
import shoppingBag from "../../common/images/shopping-bag.svg";
import noimage from "../../common/images/no-img-product.png";
import {
  GET_CART_DETAIL,
  UPDATE_CART_DETAIL,
  DELETE_CART_DETAIL,
  DESTROY_CART_DETAIL,
  GET_CATERINGCART_DETAIL,
} from "../../actions";
import { setMinutes, setHours, format } from "date-fns";
var Parser = require("html-react-parser");

class CartSideBar extends Component {
  constructor(props) {
    super(props);
    var unitNoOne =
      typeof cookie.load("unitNoOne") === "undefined"
        ? ""
        : cookie.load("unitNoOne");
    var unitNoTwo =
      typeof cookie.load("unitNoTwo") === "undefined"
        ? ""
        : cookie.load("unitNoTwo");
    var orderOutletId =
      typeof cookie.load("orderOutletId") === "undefined"
        ? ""
        : cookie.load("orderOutletId");
    var defaultAvilablityId =
      typeof cookie.load("defaultAvilablityId") === "undefined"
        ? ""
        : cookie.load("defaultAvilablityId");
    var orderTAT =
      typeof cookie.load("orderTAT") === "undefined"
        ? ""
        : cookie.load("orderTAT");
    this.state = {
      cartlistdetail: [],
      overAllcart: [],
      cartItems: [],
      cartDetails: [],
      cartStatus: "",
      settings: [],
      cartTotalItmCount: 0,
      cartTotalAmount: 0,
      cateringCartItems: [],
      cateringCartDetails: [],
      cateringCartTotalItmCount: 0,
      unitnumber1: unitNoOne,
      unitnumber2: unitNoTwo,

      /* For Advanced Slot Start */
      getDateTimeFlg: "yes",
      seleted_ord_date: "",
      seleted_ord_time: "",
      seleted_ord_slot: "",
      seleted_ord_slotTxt: "",
      seleted_ord_slot_str: "",
      seleted_ord_slot_end: "",
      isAdvanced: "no",
      slotType: "0",
      /* For Advanced Slot End */

      initialSlot: true,
      seletedOutletId: orderOutletId,
      seletedAvilablityId: defaultAvilablityId,
      order_tat_time: orderTAT,
      ordertimetype:
        cookie.load("ordertimetype") !== "" &&
        typeof cookie.load("ordertimetype") !== undefined &&
        typeof cookie.load("ordertimetype") !== "undefined"
          ? cookie.load("ordertimetype")
          : "",
      currentDayClosed: 0,
      currentDayClosedMsg: "",
    };
  }

  componentWillMount() {
    var avltyTxt = this.state.seletedAvilablityId;
    if (avltyTxt === cateringId) {
      this.props.getCateringCartDetail();
    } else {
      this.props.getCartDetail();
    }
  }

  componentWillReceiveProps(headerProps) {
    if (headerProps.cartTriggerFlg === "yes") {
      headerProps.prpSateValChange("cartflg", "no");
      this.props.getCartDetail();
      resetCrtStyle();
    }
    if (this.state.cartDetails !== headerProps.cartDetails) {
      if (Object.keys(headerProps.cartDetails).length > 0) {
        this.setState({
          cartDetails: headerProps.cartDetails,
        });
        if (this.state.initialSlot === true) {
          this.setState({ getDateTimeFlg: "yes", initialSlot: false });
        }
      }
    }
    hideLoader("product-details", "class");
    $(".cart-product-details").removeClass("loader-main-cls");
    hideLoader("productlist-main-div", "class");
    hideLoader("cart_body", "class");
  }

  componentDidMount() {}
  handleChange = (event) => {};
  removePromoFun(event) {
    event.preventDefault();
    removePromoCkValue();
    this.props.getCartDetail();
  }

  closeCartlist() {
    $("body").removeClass("cart-items-open");
    $(".hcartdd_trigger").removeClass("active");
    $(".hcart_dropdown").removeClass("open");
  }

  handleAddrChange(event) {
    if (event.target.name === "unit_no1") {
      cookie.save("unitNoOne", event.target.value, { path: "/" });
      this.setState({ unitnumber1: event.target.value });
    } else if (event.target.name === "unit_no2") {
      cookie.save("unitNoTwo", event.target.value, { path: "/" });
      this.setState({ unitnumber2: event.target.value });
    }
  }

  /* For Advanced Slot */
  setdateTimeFlg = (field, value) => {
    if (field == "tmflg") {
      this.setState({ getDateTimeFlg: value });
    } else if (field == "ordDate") {
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_date: value,
        seleted_ord_time: ordTime,
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      });
    } else if (field == "ordTime") {
      var tmSltArr = value;
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState(
        {
          seleted_ord_time: tmSltArr["startTime"],
          seleted_ord_slot: ordTime,
          seleted_ord_slotTxt: ordTime,
          seleted_ord_slot_str: ordTime,
          seleted_ord_slot_end: ordTime,
        },
        function () {
          this.setOrderOutletDateTimeDataNew();
        }.bind(this)
      );
    } else if (field == "ordSlotDate") {
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_date: value,
        seleted_ord_time: ordTime,
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      });
    } else if (field == "ordSlotTime") {
      var tmSltArr = value;
      $(".ordrdatetime_error").html("");
      this.setState(
        {
          seleted_ord_time: tmSltArr["startTime"],
          seleted_ord_slot: tmSltArr["ordSlotVal"],
          seleted_ord_slotTxt: tmSltArr["ordSlotLbl"],
          seleted_ord_slot_str: tmSltArr["ordSlotStr"],
          seleted_ord_slot_end: tmSltArr["ordSlotEnd"],
        },
        function () {
          this.setOrderOutletDateTimeDataNew();
        }.bind(this)
      );
    } else if (field == "triggerErrorPopup") {
      $.magnificPopup.open({
        items: {
          src: "#outlet-error-popup",
        },
        type: "inline",
      });
    }
  };

  setOrderOutletDateTimeDataNew() {
    var seletedOrdDate = this.state.seleted_ord_date;
    var seletedOrdTime = this.state.seleted_ord_time;
    if (
      seletedOrdDate !== "" &&
      seletedOrdTime !== "" &&
      seletedOrdDate !== null &&
      seletedOrdTime !== null
    ) {
      var OrderDate = format(seletedOrdDate, "yyyy-MM-dd");
      var OrderHours = seletedOrdTime.getHours();
      var OrderMunts = seletedOrdTime.getMinutes();
      var OrderSecnd = seletedOrdTime.getSeconds();
      var orderDateTime = new Date(OrderDate);
      orderDateTime.setHours(OrderHours);
      orderDateTime.setMinutes(OrderMunts);
      orderDateTime.setSeconds(OrderSecnd);

      var deliveryDate = format(seletedOrdDate, "dd/MM/yyyy");
      var deliveryTime =
        this.convPadNew(OrderHours) +
        ":" +
        this.convPadNew(OrderMunts) +
        ":" +
        this.convPadNew(OrderSecnd);
      cookie.save("orderDateTime", orderDateTime, { path: "/" });
      cookie.save("deliveryDate", deliveryDate, { path: "/" });
      cookie.save("deliveryTime", deliveryTime, { path: "/" });

      this.props.getCartDetail();
    }
  }

  convPadNew(d) {
    var rstVl = d < 10 ? "0" + parseInt(d) : d.toString();
    return rstVl.toString();
  }

  goCheckOut() {
    if (this.state.ordertimetype === "") {
      showAlert("Warning", "Please Choose Now or Advance time");
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    } else {
      if (this.state.currentDayClosedMsg !== "") {
        showAlert("Warning", this.state.currentDayClosedMsg);
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
      } else {
        this.props.history.push("/checkout");
      }
    }
  }

  cartDetailsList() {
    var cartItemsArr = this.props.cartItems;

    if (Object.keys(cartItemsArr).length > 0) {
      var cartDetailsArr = this.props.cartDetails;
      var promoTionArr = getPromoCkValue();

      var globalSettings = Array();
      var advancedTimeslotEnable = "0";
      if (Object.keys(this.props.globalsettings).length > 0) {
        if (this.props.globalsettings[0].status === "ok") {
          globalSettings = this.props.globalsettings[0].result_set;
          advancedTimeslotEnable =
            globalSettings.client_advanced_timeslot_enable;
        }
      }

      var zoneDetails = this.props.zonedetails;
      var calculatedAmount = getCalculatedAmount(
        globalSettings,
        zoneDetails,
        cartDetailsArr,
        cartItemsArr,
        promoTionArr
      );

      var orderDateTime =
        typeof cookie.load("orderDateTime") === "undefined"
          ? ""
          : cookie.load("orderDateTime");
      var orderTAT =
        typeof cookie.load("orderTAT") === "undefined"
          ? ""
          : cookie.load("orderTAT");
      var orderDateTmTxt = getOrderDateTime(orderDateTime, orderTAT);

      return (
        <>
          <div
            className={
              this.props.pageName === "products"
                ? "hcart_dropdown open"
                : "hcart_dropdown"
            }
          >
            <div className="product_orders_top">
              <h4>Your Cart</h4>
              <span className="close_product_orders">
                {" "}
                <img
                  src={crossImg}
                  alt=""
                  onClick={this.closeCartlist.bind(this)}
                />
              </span>
            </div>
            <div className="hcart_scrollarea">
              <div className="cart_body">
                {cookie.load("defaultAvilablityId") === deliveryId ? (
                  <>
                    <div className="pickup-cart-div">
                      <div className="cart_row cart-header-first">
                        <h4>Order Handle By</h4>
                        <p>{cookie.load("orderOutletName")}</p>
                        <p>{cookie.load("orderHandledByText")}</p>
                      </div>
                    </div>
                    <div className="del_address">
                      <h5>Delivery Location</h5>
                      <p>
                        {cookie.load("orderDeliveryAddress")}, Singapore{" "}
                        {cookie.load("orderPostalCode")}
                      </p>
                      <div className="form-group half-fg">
                        <span>#</span>
                        <div className="input_field">
                          <input
                            type="text"
                            placeholder="Unit Number 1"
                            id="unit_no1_id"
                            name="unit_no1"
                            value={this.state.unitnumber1}
                            className="form-control input-focus"
                            onChange={this.handleAddrChange.bind(this)}
                          />
                        </div>
                        <div className="input_field">
                          <input
                            type="text"
                            placeholder="Unit Number 2"
                            id="unit_no2_id"
                            name="unit_no2"
                            value={this.state.unitnumber2}
                            className="form-control input-focus"
                            onChange={this.handleAddrChange.bind(this)}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="pickup-cart-div">
                    <div className="cart_row cart-header-first">
                      <h4>Pickup Location</h4>
                      <p>{cookie.load("orderOutletName")}</p>
                      <p>{cookie.load("orderHandledByText")}</p>
                    </div>
                  </div>
                )}
                <div className="cart-order-time">
                  <ul className="order-time-slot">
                    <li
                      className={
                        this.state.ordertimetype === "now" ? "active" : ""
                      }
                    >
                      <a
                        href={void 0}
                        onClick={this.selectOrderTimeType.bind(this, "now")}
                      >
                        Now
                      </a>
                    </li>
                    <li
                      className={
                        this.state.ordertimetype === "advanced" ? "active" : ""
                      }
                    >
                      <a
                        href={void 0}
                        onClick={this.selectOrderTimeType.bind(
                          this,
                          "advanced"
                        )}
                      >
                        Advanced
                      </a>
                    </li>
                  </ul>
                  {this.state.ordertimetype === "now" &&
                    this.loadNowOrderTiming()}
                  {this.state.ordertimetype === "advanced" && (
                    <div className="form-group">
                      {
                        <OrderAdvancedDatetimeSlot
                          {...this.props}
                          hdrState={this.state}
                          setdateTimeFlg={this.setdateTimeFlg}
                        />
                      }
                      <div className="ordrdatetime_error"></div>
                    </div>
                  )}
                </div>
                <div className="overall-parent">
                  <div className="order-details-with-clear">
                    <h5>Your Items</h5>
                    <a
                      href="/"
                      onClick={this.clearCartItm.bind(this)}
                      className="hclear_cart"
                      title="CLEAR CART"
                    >
                      Clear Cart
                    </a>
                  </div>
                  <div className="product-details-parent">
                    {this.cartItemList()}
                  </div>
                </div>
              </div>

              <div className="cart_footer">
                <div className="cart_row">
                  <p className="text-uppercase">SUBTOTAL</p>
                  <span>S${calculatedAmount["cartSubTotalAmount"]}</span>
                </div>
                {parseFloat(calculatedAmount["deliveryCharge"]) > 0 && (
                  <div className="cart_row">
                    <p className="text-uppercase">Delivery</p>

                    <span>
                      S$
                      {parseFloat(calculatedAmount["deliveryCharge"]).toFixed(
                        2
                      )}
                    </span>
                  </div>
                )}
                {parseFloat(calculatedAmount["additionalDelivery"]) > 0 && (
                  <div className="cart_row">
                    <p className="text-uppercase">Additional Delivery</p>

                    <span>
                      S$
                      {parseFloat(
                        calculatedAmount["additionalDelivery"]
                      ).toFixed(2)}
                    </span>
                  </div>
                )}
                {cookie.load("promotionApplied") === "Yes" && (
                  <div className="cart_row">
                    <p className="text-uppercase">
                      Promo Code
                      {cookie.load("promoCodeVal") !== "" &&
                      typeof cookie.load("promoCodeVal") !== undefined &&
                      typeof cookie.load("promoCodeVal") !== "undefined"
                        ? "(" + cookie.load("promoCodeVal") + ")"
                        : ""}
                    </p>
                    {parseFloat(calculatedAmount["promotionAmount"]) > 0 && (
                      <span>
                        S$
                        {parseFloat(
                          calculatedAmount["promotionAmount"]
                        ).toFixed(2)}
                      </span>
                    )}

                    <a
                      href="/"
                      onClick={this.removePromoFun.bind(this)}
                      className="cart_remove"
                    >
                      <img src={crossImg} />
                    </a>
                  </div>
                )}
                {parseFloat(calculatedAmount["orderGstAmount"]) > 0 &&
                  calculatedAmount["inclusiveGST"] === "No" && (
                    <div className="cart_row gst-row">
                      <p className="text-uppercase">
                        GST ({calculatedAmount["orderDisplayGst"]} %)
                      </p>

                      <span>
                        S$
                        {parseFloat(calculatedAmount["orderGstAmount"]).toFixed(
                          2
                        )}
                      </span>
                    </div>
                  )}
                {parseFloat(calculatedAmount["voucherDiscountAmount"]) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">
                          Voucher Disocunt Amount
                        </p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          {showPrices(
                            parseFloat(
                              calculatedAmount["voucherDiscountAmount"]
                            ).toFixed(2)
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {calculatedAmount["roundoff"] !== "" && (
                  <div className="cart_row">
                    <p className="text-uppercase">Round Off</p>

                    <span>{calculatedAmount["roundoff"]}</span>
                  </div>
                )}
                <div className="grand-total-with-gst">
                  <div className="cart_row grant-total-cls">
                    <p className="text-uppercase">Total</p>
                    <span>
                      <sup>S$</sup>
                      {calculatedAmount["grandTotalAmount"]}
                    </span>
                  </div>
                  {parseFloat(calculatedAmount["orderGstAmount"]) > 0 &&
                    calculatedAmount["inclusiveGST"] === "Yes" && (
                      <p className="inclisive-gst">
                        Inclusive GST ({calculatedAmount["orderDisplayGst"]}%):
                        S$
                        {parseFloat(calculatedAmount["orderGstAmount"]).toFixed(
                          2
                        )}
                      </p>
                    )}
                </div>
                {cookie.load("defaultAvilablityId") === deliveryId &&
                  this.loadMinDeliveryPercentage()}

                {cookie.load("defaultAvilablityId") === deliveryId &&
                  this.loadDeliveryPercentage()}

                {parseFloat(cartDetailsArr.cart_special_discount) > 0 && (
                  <div className="member-discount-total">
                    * {cartDetailsArr.cart_special_discount_type} S$
                    {cartDetailsArr.cart_special_discount} Applied
                  </div>
                )}
                {this.props.pageName === "products" && (
                  <div className="cart_action">
                    <div className="cartaction_bottom">
                      <a
                        href={void 0}
                        onClick={this.goCheckOut.bind(this)}
                        className="btn btn_pink cartaction_checkout"
                        title="Checkout Now"
                      >
                        Checkout
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {this.props.pageName === "header" && (
              <div className="cartaction_bottom">
                <a
                  href={void 0}
                  onClick={this.goCheckOut.bind(this)}
                  className="btn btn_pink cartaction_checkout"
                  title="Checkout Now"
                >
                  Checkout
                </a>
              </div>
            )}
          </div>
        </>
      );
    } else {
      return (
        <div
          className={
            this.props.pageName === "products"
              ? "hcart_dropdown open"
              : "hcart_dropdown"
          }
        >
          <div className="product_orders_top">
            <h4>Your Cart</h4>
            <span className="close_product_orders">
              {" "}
              <img
                src={crossImg}
                alt=""
                onClick={this.closeCartlist.bind(this)}
              />
            </span>
          </div>
          <div className="hcart_scrollarea">
            <div className="cart_body">
              {cookie.load("defaultAvilablityId") === deliveryId ? (
                <div className="del_address">
                  <h5>Delivery Location</h5>
                  <p>
                    {cookie.load("orderDeliveryAddress")}, Singapore{" "}
                    {cookie.load("orderPostalCode")}
                  </p>
                  <div className="form-group half-fg">
                    <span>#</span>
                    <div className="input_field">
                      <input
                        type="text"
                        placeholder="Unit Number 1"
                        className="form-control"
                        name="unit_no1"
                        onChange={this.handleAddrChange.bind(this)}
                        value={this.state.unitnumber1}
                      />
                    </div>
                    <div className="input_field">
                      <input
                        type="text"
                        placeholder="Unit Number 2"
                        className="form-control"
                        name="unit_no2"
                        onChange={this.handleAddrChange.bind(this)}
                        value={this.state.unitnumber2}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="pickup-cart-div">
                  <div className="cart_row cart-header-first">
                    <h4>Pickup Location</h4>
                    <p>{cookie.load("orderOutletName")}</p>
                    <p>{cookie.load("orderHandledByText")}</p>
                  </div>
                </div>
              )}
              <div className="cart-order-time">
                <ul className="order-time-slot">
                  <li
                    className={
                      this.state.ordertimetype === "now" ? "active" : ""
                    }
                  >
                    <a
                      href={void 0}
                      onClick={this.selectOrderTimeType.bind(this, "now")}
                    >
                      Now
                    </a>
                  </li>
                  <li
                    className={
                      this.state.ordertimetype === "advanced" ? "active" : ""
                    }
                  >
                    <a
                      href={void 0}
                      onClick={this.selectOrderTimeType.bind(this, "advanced")}
                    >
                      Advanced
                    </a>
                  </li>
                </ul>

                {this.state.ordertimetype === "now" &&
                  this.loadNowOrderTiming()}
                {this.state.ordertimetype === "advanced" && (
                  <div className="form-group">
                    {
                      <OrderAdvancedDatetimeSlot
                        {...this.props}
                        hdrState={this.state}
                        setdateTimeFlg={this.setdateTimeFlg}
                      />
                    }
                    <div className="ordrdatetime_error"></div>
                  </div>
                )}
              </div>
            </div>

            <div className="cart_footer">
              {this.props.pageName === "products" && (
                <div className="cart_action">
                  <div className="cartaction_bottom">
                    <Link
                      to={void 0}
                      onClick={this.goCheckOut.bind(this)}
                      className="btn btn_pink cartaction_checkout"
                      title="Checkout Now"
                    >
                      Checkout
                    </Link>
                  </div>
                </div>
              )}
            </div>
            {this.props.pageName === "header" && (
              <div className="cartaction_bottom">
                <a
                  href={void 0}
                  onClick={this.goCheckOut.bind(this)}
                  className="btn btn_pink cartaction_checkout"
                  title="Checkout Now"
                >
                  Checkout
                </a>
              </div>
            )}
          </div>
        </div>
      );
    }
  }

  loadNowOrderTiming() {
    var resutl = "";
    if (this.state.seleted_ord_date !== "") {
      resutl = format(this.state.seleted_ord_date, "dd/MM/yyyy h:mm a");
    } else if (
      cookie.load("orderDateTime") !== "" &&
      typeof cookie.load("orderDateTime") !== undefined &&
      typeof cookie.load("orderDateTime") !== "undefined"
    ) {
      var orderDateTimes = new Date(
        cookie.load("orderDateTime").split('"').join("")
      );

      resutl = format(orderDateTimes, "dd/MM/yyyy h:mm a");
    }
    if (resutl !== "") {
      return (
        <p>
          <b>
            {" "}
            Estimated Time of{" "}
            {this.state.seletedAvilablityId === deliveryId
              ? "Delivery"
              : "PickUp"}{" "}
            : {resutl}
          </b>
        </p>
      );
    }
  }

  selectOrderTimeType(type) {
    if (type === "advanced") {
      cookie.remove("deliveryDate", { path: "/" });
      cookie.remove("deliveryTime", { path: "/" });
      cookie.remove("orderDateTime", { path: "/" });
      this.setState({
        ordertimetype: type,
        getDateTimeFlg: "yes",
        currentDayClosedMsg: "",
      });
    } else {
      axios
        .get(
          apiUrl +
            "settings/get_currentTime?app_id=" +
            appId +
            "&tat=" +
            cookie.load("orderTAT") +
            "&outlet_id=" +
            this.state.seletedOutletId +
            "&availability_id=" +
            this.state.seletedAvilablityId
        )
        .then((res) => {
          /* Success response */
          if (res.data.status === "ok") {
            var current_time = res.data.current_time;
            var mac = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
            var isSafari =
              /constructor/i.test(window.HTMLElement) ||
              (function (p) {
                return p.toString() === "[object SafariRemoteNotification]";
              })(
                !window["safari"] ||
                  (typeof safari !== "undefined" &&
                    window["safari"].pushNotification)
              );

            var isMobile = /iPhone|iPad|iPod/i.test(navigator.userAgent);

            if (isMobile === true) {
              isSafari = true;
            }
            if (isSafari === true) {
              var current_time1 = res.data.current_time_safari.split(", ");
              var orderDateTimes = new Date(
                current_time1[0],
                current_time1[1] - 1,
                current_time1[2],
                current_time1[3],
                current_time1[4],
                current_time1[5]
              );
            } else {
              var orderDateTimes = new Date(current_time);
            }
            var orderDateTimesN = orderDateTimes;
            var deliveryDateN = format(orderDateTimes, "dd/MM/yyyy");
            /* var deliveryDateN =
              cnvrtStr(orderDateTimes.getDate()) +
              "/" +
              cnvrtStr(orderDateTimes.getMonth()) +
              "/" +
              orderDateTimes.getFullYear(); */
            var deliveryTimeN =
              cnvrtStr(orderDateTimes.getHours()) +
              ":" +
              cnvrtStr(orderDateTimes.getMinutes()) +
              ":" +
              cnvrtStr(orderDateTimes.getSeconds());
            cookie.save("orderDateTime", orderDateTimesN, { path: "/" });
            cookie.save("deliveryDate", deliveryDateN, { path: "/" });
            cookie.save("deliveryTime", deliveryTimeN, { path: "/" });
            this.setState({
              ordertimetype: type,

              seleted_ord_date: orderDateTimes,
              seleted_ord_time: orderDateTimes,
            });
          } else {
            showAlert("Error", res.data.message);
            this.setState({
              currentDayClosed: 1,
              currentDayClosedMsg: res.data.message,
            });
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });
          }
        });
    }
    cookie.save("ordertimetype", type, { path: "/" });
  }

  /* this  function used to load delivery percentage */
  loadDeliveryPercentage() {
    var freeDeliveryAmnt = 0;
    var DeliveryAmnt = 0;
    var remainAmnt = 0;
    var delPercentage = 0;

    var cartItemsArr = this.props.cartItems;

    if (Object.keys(cartItemsArr).length > 0) {
      var cartDetailsArr = this.props.cartDetails;
      var promoTionArr = getPromoCkValue();

      var globalSettings = Array();
      if (Object.keys(this.props.globalsettings).length > 0) {
        if (this.props.globalsettings[0].status === "ok") {
          globalSettings = this.props.globalsettings[0].result_set;
        }
      }

      var zoneDetails = this.props.zonedetails;
      var calculatedAmount = getCalculatedAmount(
        globalSettings,
        zoneDetails,
        cartDetailsArr,
        cartItemsArr,
        promoTionArr
      );

      freeDeliveryAmnt = parseFloat(calculatedAmount["freeDeliveryAmnt"]);
      DeliveryAmnt = parseFloat(calculatedAmount["deliveryCharge"]);
      var subTotal = parseFloat(calculatedAmount["cartSubTotalAmount"]);
      var percentage = (subTotal * 100) / freeDeliveryAmnt;
      percentage = Math.round(percentage);
      delPercentage = percentage;
      remainAmnt = parseFloat(freeDeliveryAmnt - subTotal).toFixed(2);
    }

    if (DeliveryAmnt > 0 && freeDeliveryAmnt > 0 && remainAmnt > 0) {
      return (
        <div className="cart_row progress_bar_div">
          <div className="indication">
            <div className="indication_progress">
              <span
                className="progress_bar"
                style={{ width: delPercentage + "%" }}
              />
            </div>
            <p className="help-block">S${remainAmnt} more to FREE delivery!</p>
          </div>
        </div>
      );
    }
  }

  loadMinDeliveryPercentage() {
    var minamount = 0;
    var remainAmnt = 0;
    var minPercentage = 0;
    var cartItemsArr = this.props.cartItems;

    if (Object.keys(cartItemsArr).length > 0) {
      var cartDetailsArr = this.props.cartDetails;
      var promoTionArr = getPromoCkValue();

      var globalSettings = Array();
      if (Object.keys(this.props.globalsettings).length > 0) {
        if (this.props.globalsettings[0].status === "ok") {
          globalSettings = this.props.globalsettings[0].result_set;
        }
      }

      var zoneDetails = this.props.zonedetails;
      var calculatedAmount = getCalculatedAmount(
        globalSettings,
        zoneDetails,
        cartDetailsArr,
        cartItemsArr,
        promoTionArr
      );

      minamount = parseFloat(calculatedAmount["minamount"]);
      var subTotal = parseFloat(calculatedAmount["cartSubTotalAmount"]);
      var percentage = (subTotal * 100) / minamount;
      percentage = Math.round(percentage);
      minPercentage = percentage;
      remainAmnt = parseFloat(minamount - subTotal).toFixed(2);
    }
    if (remainAmnt > 0) {
      return (
        <div className="cart_row progress_bar_div min-order">
          <div className="indication">
            <div className="indication_progress">
              <span
                className="progress_bar"
                style={{ width: minPercentage + "%" }}
              />
            </div>
            <p className="help-block">
              S${remainAmnt} more to min order amount
            </p>
          </div>
        </div>
      );
    }
  }

  cartItemList() {
    var cartItemsArr = this.props.cartItems;
    if (Object.keys(cartItemsArr).length > 0) {
      return cartItemsArr.map((product, index) => (
        <div
          className="cart_row cart-product-details"
          id={"rowcartid-" + product.cart_item_id}
          key={index}
        >
          <div className="cart-lt">
            <div className="col-sm-cls cart_left">
              <div className="cart_img">
                {product.cart_item_product_image !== "" ? (
                  <img src={product.cart_item_product_image} alt="" />
                ) : (
                  <img src={noimage} alt="" />
                )}
              </div>
              <div className="cart_info text-left">
                <h4>{stripslashes(product.cart_item_product_name)}</h4>
                {product.cart_item_voucher_id !== "" &&
                  product.cart_item_voucher_id != null && (
                    <span className="voucher-discount-applied">
                      Discount Applied
                    </span>
                  )}
                {product.cart_item_product_type === "5" &&
                  product.cart_item_product_voucher_gift_email !== "" &&
                  product.cart_item_product_voucher_gift_email !==
                    cookie.load("UserEmail") &&
                  product.cart_item_product_voucher_gift_mobile !== "" &&
                  (product.cart_voucher_order_item_id === "" ||
                    product.cart_voucher_order_item_id === null) && (
                    <div className="cart_extrainfo">
                      <p>
                        <b>Gift Details</b>
                      </p>
                      {product.cart_item_product_voucher_gift_name !== "" &&
                        product.cart_item_product_voucher_gift_name !==
                          null && (
                          <p>
                            Name: {product.cart_item_product_voucher_gift_name}
                          </p>
                        )}
                      {product.cart_item_product_voucher_gift_mobile !== "" &&
                        product.cart_item_product_voucher_gift_mobile !==
                          null && (
                          <p>
                            Mobile:{" "}
                            {product.cart_item_product_voucher_gift_mobile}
                          </p>
                        )}
                      {product.cart_item_product_voucher_gift_message !== "" &&
                        product.cart_item_product_voucher_gift_message !==
                          null && (
                          <p>
                            Email:{" "}
                            {product.cart_item_product_voucher_gift_email}
                          </p>
                        )}
                      {product.cart_item_product_voucher_gift_message !== "" &&
                        product.cart_item_product_voucher_gift_message !==
                          null && (
                          <p>
                            Message:{" "}
                            {product.cart_item_product_voucher_gift_message}
                          </p>
                        )}
                    </div>
                  )}
                {this.loadModifierItems(
                  product.cart_item_type,
                  product.modifiers,
                  product.set_menu_component
                )}
                {parseFloat(product.cart_item_promotion_discount) > 0 && (
                  <span className="member-discount-desc">
                    S$ {product.cart_item_promotion_discount}{" "}
                    {product.cart_item_promotion_type} discount Applied
                  </span>
                )}
              </div>
            </div>
            {product.cart_item_promo_free == "0" &&
              product.cart_item_cridit_free_product == "0" && (
                <div className="col-sm-cls cart_right text-right">
                  <div className="cart_price">
                    <p>S${product.cart_item_total_price}</p>
                  </div>
                  {product.cart_item_voucher_product_free != 1 ? (
                    <div className="qty_bx">
                      <span
                        className="qty_minus"
                        onClick={this.updateCartQty.bind(this, product, "decr")}
                      >
                        -
                      </span>
                      <input
                        type="text"
                        value={product.cart_item_qty}
                        readOnly
                      />
                      <span
                        className="qty_plus"
                        onClick={this.updateCartQty.bind(this, product, "incr")}
                      >
                        +
                      </span>
                    </div>
                  ) : (
                    <div className="qty_bx free_product">
                      <span className="qty_minus"></span>
                      <input
                        type="text"
                        value={product.cart_item_qty}
                        readOnly
                      />
                      <span className="qty_plus"></span>
                    </div>
                  )}
                </div>
              )}
          </div>
          <div className="cart-rt">
            {product.cart_item_special_notes !== "" && (
              <p className="notes-block">
                {stripslashes(product.cart_item_special_notes)}
              </p>
            )}
          </div>

          <a
            href="/"
            onClick={this.deleteCartItm.bind(this, product)}
            className="cart_remove"
          >
            <img src={crossImg} />
          </a>
        </div>
      ));
    }
  }

  /* this function used to load modifer items */
  loadModifierItems(itemType, modifiers, combo) {
    var len = modifiers.length;
    var comboLen = combo.length;
    var html = '<div class="cart_extrainfo deo">';

    var temp_html = "";

    if (itemType === "Modifier" && len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]["cart_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["cart_modifier_name"];
        var modVlPrice =
          modifiers[i]["modifiers_values"][0]["cart_modifier_price"];
        var newModVlPrice = modVlPrice > 0 ? " (S$+" + modVlPrice + ")" : "";
        temp_html +=
          "<p>" + modName + ":</p> <p>" + modval + newModVlPrice + "</p> ";
      }

      html += temp_html + "</div>";
      var reactElement = Parser(html);
      return reactElement;
    } else if (itemType === "Component" && comboLen > 0) {
      for (var i = 0, length = comboLen; i < length; i++) {
        var comboName = combo[i]["menu_component_name"];
        var comboVal = this.showComboProducts(combo[i]["product_details"]);
        if (combo[i]["product_details"][0]["modifiers"].length) {
          html +=
            "<h6>" +
            comboName +
            ": </h6><p>" +
            comboVal +
            "  " +
            this.showComboModifiers(
              combo[i]["product_details"][0]["modifiers"]
            ) +
            "" +
            "</p> ";
        } else {
          html +=
            "<h6>" +
            comboName +
            ": </h6><p>" +
            comboVal +
            " " +
            this.showComboModifiers(
              combo[i]["product_details"][0]["modifiers"]
            ) +
            "" +
            "</p> ";
        }
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;
    }
  }

  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length;
    var html = " ";
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        if (parseInt(combos[r]["cart_menu_component_product_qty"]) > 0) {
          var comboVal = this.showComboInCombo(combos[r]["set_menu_component"]);
          var custom_text_combo = "";
          if (
            combos[r]["custom_text"] !== "" &&
            combos[r]["custom_text"] !== null
          ) {
            custom_text_combo =
              "<i class='combo-in-combo-remarks'>" +
              combos[r]["custom_text"] +
              "</i>";
          }
          var comboPro = combos[r]["cart_menu_component_product_name"];
          var comboQty = combos[r]["cart_menu_component_product_qty"];
          var comboPrice = combos[r]["cart_menu_component_product_price"];
          var newPrice = comboPrice > 0 ? " (+S$" + comboPrice + ")" : "";
          html +=
            "<p>" +
            comboQty +
            " X " +
            comboPro +
            newPrice +
            "" +
            comboVal +
            "" +
            custom_text_combo;
          (" </p> ");
        }
      }
      return html;
    }
    return "";
  }
  showComboInCombo(combo) {
    var html = "";
    var comboLen = combo.length;
    for (var i = 0, length = comboLen; i < length; i++) {
      var comboName = combo[i]["menu_component_name"];
      var comboVal = this.showComboInComboProducts(combo[i]["product_details"]);

      html +=
        "<ul class='cart-combo-in-combo'><li>" +
        comboName +
        ": </li>" +
        comboVal +
        " " +
        "</ul> ";
    }
    return html;
  }

  showComboInComboProducts(combos) {
    var lenCombo = combos.length;
    var html = " ";
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        if (parseInt(combos[r]["cart_inner_menu_component_product_qty"]) > 0) {
          var comboPro = combos[r]["cart_inner_menu_component_product_name"];
          var comboQty = combos[r]["cart_inner_menu_component_product_qty"];
          var comboPrice = combos[r]["cart_inner_menu_component_product_price"];
          var newPrice = comboPrice > 0 ? " (+S$" + comboPrice + ")" : "";
          html += "<li>" + comboQty + " X " + comboPro + newPrice + " </li> ";
        }
      }
      return html;
    }
    return "";
  }
  /* this function used to show combo modifieirs list */
  showComboModifiers(modifiers) {
    var lenMod = modifiers.length;
    var html = "<div >";
    if (lenMod > 0) {
      for (var i = 0, length = lenMod; i < length; i++) {
        var modName = modifiers[i]["cart_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["cart_modifier_name"];
        var modValPrice =
          modifiers[i]["modifiers_values"][0]["cart_modifier_price"];
        var newModValPrice = modValPrice > 0 ? " (+" + modValPrice + ")" : "";
        html +=
          "<p><b>" +
          modName +
          ":</b> </p><p> " +
          modval +
          newModValPrice +
          "</p> ";
      }
      html += "</div>";

      return html;
    }

    return "";
  }

  updateCartQty(itemArr, type) {
    var productId = itemArr.cart_item_product_id;
    var cartItemId = itemArr.cart_item_id;
    var cartQty = itemArr.cart_item_qty;
    var orderVoucherId = itemArr.cart_voucher_order_item_id;
    var totalItmCount = this.props.cartTotalItmCount;

    showLoader("rowcartid-" + cartItemId, "Idtext");

    if (type === "decr") {
      cartQty = parseInt(cartQty) - 1;
      if (parseInt(totalItmCount) === 0) {
      } else if (parseInt(cartQty) === 0) {
        this.props.deleteCartDetail(cartItemId);
      } else {
        this.props.updateCartDetail(
          productId,
          cartItemId,
          cartQty,
          orderVoucherId
        );
      }
    } else {
      cartQty = parseInt(cartQty) + 1;
      this.props.updateCartDetail(
        productId,
        cartItemId,
        cartQty,
        orderVoucherId
      );
    }
    removePromoCkValue();
  }

  deleteCartItm(itemArr, event) {
    event.preventDefault();
    var cartItemId = itemArr.cart_item_id;
    showLoader("rowcartid-" + cartItemId, "Idtext");
    this.props.deleteCartDetail(cartItemId);
    removePromoCkValue();
  }

  clearCartItm(event) {
    event.preventDefault();
    showLoader("cart_body", "class");
    this.props.destroyCartDetail();
    removePromoCkValue();
  }

  gotoCateringChkut(event) {
    event.preventDefault();

    var cartTotal = this.props.cateringCartTotalItmCount;
    /*if(parseFloat(cartTotal)>0) {*/
    cookie.save("cateringCheckoutFromCart", "Yes", { path: "/" });
    this.props.history.push("/");
    /*}*/
  }

  render() {
    var avltyTxt = cookie.load("defaultAvilablityId");
    return (
      <>
        {this.props.pageName === "header" &&
          (avltyTxt === cateringId ? (
            <li className="htico_cart">
              <a
                href="/"
                onClick={this.gotoCateringChkut.bind(this)}
                className="hcartdd_trigger"
                title=""
              >
                <img src={shoppingBag} alt="" />
                {parseInt(this.props.cateringCartTotalItmCount) > 0 && (
                  <span className="hcart_round">
                    {this.props.cateringCartTotalItmCount}
                  </span>
                )}
              </a>
            </li>
          ) : (
            <li className="htico_cart">
              <Link to={"/checkout"} className="hcartdd_trigger" title="">
                <img src={shoppingBag} alt="" />
                {parseInt(this.props.cartTotalItmCount) > 0 && (
                  <span className="hcart_round">
                    {this.props.cartTotalItmCount}
                  </span>
                )}
              </Link>
              <input
                type="hidden"
                value={this.props.cartTotalItmCount}
                id="totalitmcount"
              />
              <input
                type="hidden"
                value={
                  (this.props.match.path === "/products/:slugType/:slugValue" ||
                    this.props.match.path === "/products" ||
                    this.props.match.path === "/favourite") &&
                  $("body").width() > 980
                    ? "Yes"
                    : "No"
                }
                id="isproductpage"
              />
            </li>
          ))}
        {this.props.pageName === "header" ? (
          this.props.match.path !== "/products/:slugType/:slugValue" &&
          this.props.match.path !== "/products" &&
          this.props.match.path !== "/favourite" && (
            <li>{this.cartDetailsList()}</li>
          )
        ) : (
          <li>{this.cartDetailsList()}</li>
        )}
        {this.props.pageName === "header"
          ? (this.props.match.path === "/products/:slugType/:slugValue" ||
              this.props.match.path === "/products" ||
              this.props.match.path === "/favourite") &&
            $("body").width() <= 980 && <li>{this.cartDetailsList()}</li>
          : ""}
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var overAllcart = Array();
  var cartDetails = Array();
  var cartItems = Array();
  var cartTotalItmCount = 0;
  var cartStatus = "";
  if (Object.keys(state.cartlistdetail).length > 0) {
    var resultSetArr = !("result_set" in state.cartlistdetail[0])
      ? Array()
      : state.cartlistdetail[0].result_set;
    if (
      state.cartlistdetail[0].status === "ok" &&
      Object.keys(resultSetArr).length > 0
    ) {
      overAllcart = resultSetArr;
      cartDetails = resultSetArr.cart_details;
      cartItems = resultSetArr.cart_items;
      cartTotalItmCount = resultSetArr.cart_details.cart_total_items;
      cartStatus = "success";
    } else {
      cartStatus = "failure";
    }
  }

  var cateringCartDetails = Array();
  var cateringCartItems = Array();
  var cateringCartTotalItmCount = 0;
  if (Object.keys(state.cateringcartlistdetail).length > 0) {
    var resultSetArr = !("result_set" in state.cateringcartlistdetail[0])
      ? Array()
      : state.cateringcartlistdetail[0].result_set;
    if (
      state.cateringcartlistdetail[0].status === "ok" &&
      Object.keys(resultSetArr).length > 0
    ) {
      cateringCartDetails = resultSetArr.cart_details;
      cateringCartItems = resultSetArr.cart_items;
      cateringCartTotalItmCount = resultSetArr.cart_details.cart_total_items;
    }
  }

  return {
    overAllcart: overAllcart,
    cartDetails: cartDetails,
    cartItems: cartItems,
    cartTotalItmCount: cartTotalItmCount,
    cartStatus: cartStatus,
    cateringCartDetails: cateringCartDetails,
    cateringCartItems: cateringCartItems,
    cateringCartTotalItmCount: cateringCartTotalItmCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCartDetail: () => {
      dispatch({ type: GET_CART_DETAIL });
    },
    updateCartDetail: (productId, cartItemId, cartQty, orderVoucherId) => {
      dispatch({
        type: UPDATE_CART_DETAIL,
        productId,
        cartItemId,
        cartQty,
        orderVoucherId,
      });
    },
    deleteCartDetail: (cartItemId) => {
      dispatch({ type: DELETE_CART_DETAIL, cartItemId });
    },
    destroyCartDetail: () => {
      dispatch({ type: DESTROY_CART_DETAIL });
    },
    getCateringCartDetail: () => {
      dispatch({ type: GET_CATERINGCART_DETAIL });
    },
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(CartSideBar);
