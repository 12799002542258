/* Live */
export const appId = "F043F896-84FA-48E8-88C5-FC0B4F6450B0";

/* Live */
/* export const apiUrl = "https://titan.ninjaos.com/api/";
export const apiUrlV2 = "https://titan.ninjaos.com/apiv2/";
export const apiUrlPro = "https://titan.ninjaos.com/ninjapro/";
export const apiUrlCtrng = "https://titan.ninjaos.com/cateringv2/";
export const apiUrlNotify = "https://titan.ninjaos.com/Pushorder/";
export const baseUrl = "https://canadian-pizza.com/";
//export const baseUrl = "https://dev.canadian-pizza.com/";
export const timThumpUrl = "https://titan.ninjaos.com/timthumb.php?src=";
export const mediaUrl = "https://titan.ninjaos.com/media/dev_team/";
export const blogImageUrl = "https://titan.ninjaos.com/media/dev_team/blog/";
export const tagImageUrl = "https://titan.ninjaos.com/media/dev_team/tag/";
export const proFolderUrl =
  "https://titan.ninjaos.com/media/dev_team/products/"; */

/* ============================================================================*/

/* Development */
export const apiUrl = "https://titan.ninjaos.com/development/api/";
export const apiUrlV2 = "https://titan.ninjaos.com/development/apiv2/";
export const apiUrlPro = "https://titan.ninjaos.com/development/ninjapro/";
export const apiUrlCtrng = "https://titan.ninjaos.com/development/cateringv2/";
export const apiUrlNotify = "https://titan.ninjaos.com/development/Pushorder/";
//export const baseUrl = "https://canadianpizza.promobuddy.asia/";
export const baseUrl = "https://dev.canadian-pizza.com/";
//export const baseUrl = "http://localhost:3000/";
export const timThumpUrl =
  "https://titan.ninjaos.com/development/timthumb.php?src=";
export const mediaUrl = "https://titan.ninjaos.com/development/media/dev_team/";
export const blogImageUrl =
  "https://titan.ninjaos.com/development/media/dev_team/blog/";
export const tagImageUrl =
  "https://titan.ninjaos.com/development/media/dev_team/tag/";
export const proFolderUrl =
  "https://titan.ninjaos.com/media/dev_team/products/";

/* ============================================================================*/

export const stripeImage = "/img/stripe_logo.png";

export const deliveryId = "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11";
export const pickupId = "718B1A92-5EBB-4F25-B24D-3067606F67F0";
export const cateringId = "EB62AF63-0410-47CC-9464-038E796E28C4";
export const reservationId = "79FA4C7F-75A1-4A95-B7CE-81ECA2575363";

export const CountryTxt = "Singapore";
export const productNoImg = "/img/product-noimg.jpg";
export const stripeCompany = "Canadian 2 For 1 Pizza";

export const fbAppId = "342134490990362";
export const googleAppId =
  "867251468765-dbmk4cabaa791rvd5fkova5b1lfbnt6d.apps.googleusercontent.com";

export const stripeKey = "pk_test_hLfht4KEShHlACDG9mBfrPkv";
export const stripeEmail = "dev@jankosoft.com";
export const stripeReference = "canadianpizza";
export const stripeDesc = "My Checkout";
export const stripeCurrency = "SGD";
export const currencysymbol = "S$";
export const cookieage = 7776000;
export const defaultOutletID = 333;
export const app_version = "1.14";
