/* eslint-disable */
import React, { Component } from "react";
import { appId, apiUrl, deliveryId, apiUrlV2 } from "../../Helpers/Config";
import { stripslashes } from "../../Helpers/SettingHelper";
import { Link } from "react-router-dom";
import axios from "axios";
import _ from "underscore";
var Parser = require("html-react-parser");
import cookie from "react-cookies";
export default class MenuNavigationmob extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuList: [],
      subCategory: [],
      navigateCommon: "",
    };
  }

  componentDidMount() {
    var availabilityId =
      cookie.load("defaultAvilablityId") !== "" &&
      typeof cookie.load("defaultAvilablityId") !== undefined &&
      typeof cookie.load("defaultAvilablityId") !== "undefined"
        ? cookie.load("defaultAvilablityId")
        : deliveryId;
    var orderOutletId =
      cookie.load("orderOutletId") === undefined ||
      cookie.load("orderOutletId") === "null" ||
      cookie.load("orderOutletId") == ""
        ? ""
        : cookie.load("orderOutletId");
    axios
      .get(
        apiUrlV2 +
          "products/getMenuNavigation?app_id=" +
          appId +
          "&availability=" +
          availabilityId +
          "&outletId=" +
          orderOutletId +
          "&source_type=ecommerce"
      )
      .then((res) => {
        if (res.data.status == "ok") {
          if (res.data.result_set !== null) {
            this.setState({
              menuList: res.data.result_set,
              navigateCommon: res.data.common,
            });
            cookie.save(
              "firstNavigation",
              res.data.result_set[0].pro_cate_slug,
              {
                path: "/",
              }
            );
          }
        }
      });
  }
  endSubMenuItems(submenu, categorySlug) {
    return submenu.map((loaddata, index) => (
      <li key={index}>
        {" "}
        <Link
          to={
            "/refpage/gotoproduct/" +
            categorySlug +
            "/" +
            loaddata.pro_subcate_slug
          }
          title={stripslashes(loaddata.pro_subcate_name)}
          /* onClick={(e) => {
            e.preventDefault();
            this.goCategoryProducts(loaddata.pro_subcate_slug);
          }} */
        >
          {stripslashes(loaddata.pro_subcate_name)}{" "}
        </Link>
      </li>
    ));

    /*return submenu.map((loaddata, index) => <li key={index} > <Link to={"/products/category/" + categorySlug + "/" + loaddata.pro_subcate_slug} title={stripslashes(loaddata
			.pro_subcate_name)} >{stripslashes(loaddata.pro_subcate_name)} </Link></li >);*/
  }

  /* submenu listing */
  showSubMenu(isSubMenu, objects, categorySlug) {
    if (isSubMenu == "Yes") {
      var chunkValues = _.chunk(objects.sub_result_set, 4);
      const listingItems = chunkValues.map((submenu, index) => {
        return (
          <ul className="hcategory_submenu" key={index}>
            {this.endSubMenuItems(submenu, categorySlug)}{" "}
          </ul>
        );
      });
      return listingItems;
    }
  }

  goCategoryProducts(selectedNavigation) {
    this.setState({ selectedNavigation: selectedNavigation });
    $("html, body").animate(
      {
        scrollTop: $("#pro_cat_" + selectedNavigation).offset().top - 200,
      },
      1000
    );
    $(".more-menu-parent, .more_categor_info").removeClass("active");
    $(".more_categor_info").hide();
  }

  render() {
    var currenturl = window.location.href;
    // var navigateCommon = this.state.menuList.common;
    const mainMenu = this.state.menuList.map((loaddata, index) => {
      if (loaddata.menu_type == "main") {
        return (
          <li
            key={index}
            className={
              currenturl.includes(loaddata.pro_cate_slug) ? "active" : ""
            }
          >
            {" "}
            <Link
              to={"/products/category/" + loaddata.pro_cate_slug}
              title={loaddata.menu_custom_title}
              className="canadian-cat-img"
              onClick={(e) => {
                e.preventDefault();
                // this.goCategoryProducts(loaddata.pro_cate_slug);
              }}
            >
              {loaddata.pro_cate_default_image !== "" &&
                loaddata.pro_cate_default_image !== null && (
                  <img
                    src={
                      this.state.navigateCommon.category_image_url +
                      "/" +
                      loaddata.pro_cate_default_image
                    }
                    alt={stripslashes(loaddata.menu_custom_title)}
                  />
                )}
              <span className="can-submenu">
                {stripslashes(loaddata.menu_custom_title)}{" "}
              </span>
            </Link>{" "}
            {this.showSubMenu(
              loaddata.is_sub_list,
              loaddata.subcat_list_arr,
              loaddata.pro_cate_slug
            )}{" "}
          </li>
        );
      } else if (loaddata.menu_type == "sub") {
        // return (
        //   <li
        //     key={index}
        //     className={
        //       currenturl.includes(loaddata.pro_cate_slug) ? "active" : ""
        //     }
        //   >
        //     {" "}
        //     <Link
        //       to={"/products/subcategory/" + loaddata.pro_subcate_slug}
        //       title={stripslashes(loaddata.menu_custom_title)}
        //     >
        //       {" "}
        //       {loaddata.menu_custom_title}{" "}
        //     </Link>{" "}
        //   </li>
        // );
      }
    });
    return <ul className="hcategory_menulist">{mainMenu} </ul>;
  }
}
