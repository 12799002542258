/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import footerLogo from "../../common/images/logo.png";
import halalLogo from "../../common/images/halal-logo.png";
import axios from "axios";
import { Accordion, AccordionItem } from "react-light-accordion";
import "react-light-accordion/demo/css/index.css";

import { GET_STATIC_BLOCK } from "../../actions";
import {
  showLoader,
  hideLoader,
  validateEmailFun,
  showCustomAlert,
} from "../Helpers/SettingHelper";
import { appId, apiUrl, app_version } from "../Helpers/Config";
var Parser = require("html-react-parser");
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      footerBlocks: "",
      footerlink: "",
      mobilefooterpizza: "",
      mobilefooterpizzatitle: "",
      mobilefooterpromotion: "",
      mobilefooterpromotiontitle: "",
      mobilefooteraboutus: "",
      mobilefooteraboutustitle: "",
      mobilefootermyaccount: "",
      mobilefootermyaccounttitle: "",
      mobilefooterlocations: "",
      mobilefooterlocationstitle: "",
      mobilefooterpolicies: "",
      mobilefooterpoliciestitle: "",
      likeus: "",
      promo_mail_id: "",
      promomail_error: "",
    };
  }

  componentDidMount() {
    this.props.getStaticBlock();

    var btn = $("#scrollbutton");

    $(window).scroll(function () {
      if ($(window).scrollTop() > 300) {
        btn.addClass("show");
      } else {
        btn.removeClass("show");
      }
    });

    btn.on("click", function (e) {
      e.preventDefault();
      $("html, body").animate({ scrollTop: 0 }, "300");
    });
  }

  componentWillReceiveProps(PropsData) {
    if (PropsData.staticblack !== this.state.footerBlocks) {
      var footerPas = "",
        mobilefooterpizza = "",
        mobilefooterpizzatitle = "",
        mobilefooterpromotion = "",
        mobilefooterpromotiontitle = "",
        mobilefooteraboutus = "",
        mobilefooteraboutustitle = "",
        mobilefootermyaccount = "",
        mobilefootermyaccounttitle = "",
        mobilefooterlocations = "",
        mobilefooterlocationstitle = "",
        mobilefooterpolicies = "",
        mobilefooterpoliciestitle = "",
        likeus = "";
      if (Object.keys(PropsData.staticblack).length > 0) {
        PropsData.staticblack.map((data) => {
          if (data.staticblocks_slug === "footer-contents") {
            footerPas = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "mobile-footer-pizza") {
            mobilefooterpizza = data.staticblocks_description;
            mobilefooterpizzatitle = data.staticblocks_title.replace(
              "Mobile Footer ",
              ""
            );
            return "";
          }
          if (data.staticblocks_slug === "mobile-footer-promotion") {
            mobilefooterpromotion = data.staticblocks_description;
            mobilefooterpromotiontitle = data.staticblocks_title.replace(
              "Mobile Footer ",
              ""
            );
            return "";
          }
          if (data.staticblocks_slug === "mobile-footer-about-us") {
            mobilefooteraboutus = data.staticblocks_description;
            mobilefooteraboutustitle = data.staticblocks_title.replace(
              "Mobile Footer ",
              ""
            );
            return "";
          }
          if (data.staticblocks_slug === "mobile-footer-my-account") {
            mobilefootermyaccount = data.staticblocks_description;
            mobilefootermyaccounttitle = data.staticblocks_title.replace(
              "Mobile Footer ",
              ""
            );
            return "";
          }
          if (data.staticblocks_slug === "mobile-footer-locations") {
            mobilefooterlocations = data.staticblocks_description;
            mobilefooterlocationstitle = data.staticblocks_title.replace(
              "Mobile Footer ",
              ""
            );
            return "";
          }
          if (data.staticblocks_slug === "mobile-footer-policies") {
            mobilefooterpolicies = data.staticblocks_description;
            mobilefooterpoliciestitle = data.staticblocks_title.replace(
              "Mobile Footer ",
              ""
            );
            return "";
          }
          if (data.staticblocks_slug === "like-us") {
            likeus = data.staticblocks_description;
            return "";
          }
        });
      }

      footerPas = footerPas !== "" ? Parser(footerPas) : footerPas;
      mobilefooterpizza =
        mobilefooterpizza !== ""
          ? Parser(mobilefooterpizza)
          : mobilefooterpizza;
      mobilefooterpromotion =
        mobilefooterpromotion !== ""
          ? Parser(mobilefooterpromotion)
          : mobilefooterpromotion;
      mobilefooteraboutus =
        mobilefooteraboutus !== ""
          ? Parser(mobilefooteraboutus)
          : mobilefooteraboutus;
      mobilefootermyaccount =
        mobilefootermyaccount !== ""
          ? Parser(mobilefootermyaccount)
          : mobilefootermyaccount;
      mobilefooterlocations =
        mobilefooterlocations !== ""
          ? Parser(mobilefooterlocations)
          : mobilefooterlocations;
      mobilefooterpolicies =
        mobilefooterpolicies !== ""
          ? Parser(mobilefooterpolicies)
          : mobilefooterpolicies;
      likeus = likeus !== "" ? Parser(likeus) : likeus;

      this.setState(
        {
          footerBlocks: PropsData.staticblack,
          footerlink: footerPas,
          mobilefooterpizza: mobilefooterpizza,
          mobilefooterpizzatitle: mobilefooterpizzatitle,
          mobilefooterpromotion: mobilefooterpromotion,
          mobilefooterpromotiontitle: mobilefooterpromotiontitle,
          mobilefooteraboutus: mobilefooteraboutus,
          mobilefooteraboutustitle: mobilefooteraboutustitle,
          mobilefootermyaccount: mobilefootermyaccount,
          mobilefootermyaccounttitle: mobilefootermyaccounttitle,
          mobilefooterlocations: mobilefooterlocations,
          mobilefooterlocationstitle: mobilefooterlocationstitle,
          mobilefooterpolicies: mobilefooterpolicies,
          mobilefooterpoliciestitle: mobilefooterpoliciestitle,
          likeus: likeus,
        },
        function () {
          this.loadFotterMobileLinks();
        }
      );
    }
  }
  handleEmailChange(event) {
    this.setState({ promo_mail_id: event.target.value, promomail_error: "" });
  }
  sendPromoMailFun() {
    var promoEmail = this.state.promo_mail_id;

    var mailErrorTxt = "";
    if (promoEmail === "") {
      mailErrorTxt = "Email Address is required.";
    } else if (!validateEmailFun(promoEmail)) {
      mailErrorTxt = "Invalide Email Address";
    }

    if (mailErrorTxt !== "") {
      this.setState({ promomail_error: mailErrorTxt });
      var current = this;
      setTimeout(function () {
        current.setState({ current: "" });
      }, 3000);
      return false;
    } else {
      showLoader("promomailpopup-cls", "class");
      var qs = require("qs");
      var postObject = {
        app_id: appId,
        email_address: promoEmail,
      };

      axios
        .post(apiUrl + "promotion/user_promotion", qs.stringify(postObject))
        .then((response) => {
          hideLoader("promomailpopup-cls", "class");
          $.magnificPopup.close();
          this.setState({ promo_mail_id: "" });
          if (response.data.status === "ok") {
            showCustomAlert(
              "success",
              "You are now a Member of Canadian 2 For 1 Pizza. Please check your email for more information."
            );
          } else {
            var errMsgtxt =
              response.data.message !== ""
                ? response.data.message
                : "Sorry! You didn`t have promo code.";
            showCustomAlert("error", errMsgtxt);
          }
          return false;
        });
    }
    return false;
  }

  loadFotterMobileLinks() {
    var resulte = (
      <Accordion>
        <AccordionItem title={this.state.mobilefooterpizzatitle}>
          {this.state.mobilefooterpizza}
        </AccordionItem>
        <AccordionItem title={this.state.mobilefooterpromotiontitle}>
          {this.state.mobilefooterpromotion}
        </AccordionItem>
        <AccordionItem title={this.state.mobilefooteraboutustitle}>
          {this.state.mobilefooteraboutus}
        </AccordionItem>
        <AccordionItem title={this.state.mobilefootermyaccounttitle}>
          {this.state.mobilefootermyaccount}
        </AccordionItem>

        <AccordionItem title={this.state.mobilefooterlocationstitle}>
          {this.state.mobilefooterlocations}
        </AccordionItem>
        <AccordionItem title={this.state.mobilefooterpoliciestitle}>
          {this.state.mobilefooterpolicies}
        </AccordionItem>
      </Accordion>
    );
    this.setState({ footerMobileLinks: resulte });
  }
  render() {
    var todayTimeSp = new Date();
    var yearSp = todayTimeSp.getFullYear();

    return (
      <>
        {this.state.likeus !== "" && (
          <section className="offer-deals">
            <div className="container">
              <div className="offer-box offer-deals-lhs">
                {/*  <h2>Love Pizza ? Like Deals</h2>
              <p>
                Sample text line will be here for this section. sample text is
                here sample text for this area.
              </p>
              <div className="offer-deal-form">
                <input
                  type="text"
                  className="odf-input"
                  value={this.state.promo_mail_id}
                  onChange={this.handleEmailChange.bind(this)}
                />
                <div className="promomailpopup-cls">
                  <input
                    type="submit"
                    className="button odf-button"
                    value="Subscribe"
                    onClick={this.sendPromoMailFun.bind(this)}
                  />
                </div>
                {this.state.promomail_error !== "" && (
                  <div id="promomail-error">{this.state.promomail_error}</div>
                )}
              </div> */}
              </div>
              <div className="offer-box offer-deals-rhs">
                {this.state.likeus}
              </div>
            </div>
          </section>
        )}
        <footer className="footer-main">
          <div className="footer-nav-info">
            <div className="container-full">
              <div className="f-logo">
                <div className="mobile-footer-logo">
                  <Link to={"/"} title="Canadian 2 For 1 Pizza">
                    <img src={footerLogo} />
                  </Link>
                </div>
                <div className="mobile-f-halal">
                  <img src={halalLogo} />
                </div>
              </div>
              {this.state.footerlink}
              <div className="mobile-footer-nav">
                {this.state.footerMobileLinks}
              </div>
              <div className="f-halal">
                <img src={halalLogo} />
              </div>
            </div>
          </div>

          <div className="copyright-section">
            <p>
              Version:{app_version} Copyright {yearSp} Canadian 2 For 1 Pizza.
              All rights reserved.{" "}
              <a href="https://www.ninjaos.com/" target="_blank">
                Powered By NinjaOS
              </a>
            </p>
          </div>

          <div className="scrolltop" id="scrollbutton">
            <a href="/" className="disbl_href_action">
              <span>
                <i className="fa fa-angle-double-up ars"></i>
                <i className="fa fa-angle-up ars1"></i>
                <i className="fa fa-angle-up ars2"></i>
              </span>
            </a>
          </div>
        </footer>
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }
  return {
    staticblack: blacksArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Footer);
