/* eslint-disable */
import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import cookie from "react-cookies";
import { connect } from "react-redux";
import update from "immutability-helper";
import Axios from "axios";
import Slider from "react-slick";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import MenuNavigation from "./MenuNavigation";
import ProductList from "./ProductList";
import bannerImage from "../../common/images/inner-promo-banner.jpg";
import noimage from "../../common/images/no-img-product.png";
import {
  appId,
  apiUrlV2,
  apiUrl,
  tagImageUrl,
  cateringId,
  deliveryId,
} from "../Helpers/Config";
import {
  showLoader,
  stripslashes,
  showPriceValue,
  getReferenceID,
  hideLoader,
  removePromoCkValue,
  showCustomAlert,
  showAlert,
} from "../Helpers/SettingHelper";
import {
  GET_GLOBAL_SETTINGS,
  GET_MENU_NAVIGATION,
  GET_ZONE_DETAIL,
  GET_ADDONPRODUCT,
} from "../../actions";
import CartSideBar from "../Layout/CartSideBar";

var qs = require("qs");
const isEqual = require("react-fast-compare");
var Parser = require("html-react-parser");
var settingsAddons = {
  infinite: false,
  dots: true,
  arrows: false,
  slidesToShow: 2,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1191,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: false,
      },
    },
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 680,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 0,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
};
var sliderchecked = "";
class Products extends Component {
  constructor(props) {
    super(props);
    var subcatSlug =
      this.props.match.params.slugValue !== "" &&
      typeof this.props.match.params.slugValue !== undefined &&
      typeof this.props.match.params.slugValue !== "undefined"
        ? this.props.match.params.slugValue
        : "";
    var slugTypeN =
      this.props.match.params.slugType !== "" &&
      typeof this.props.match.params.slugType !== undefined &&
      typeof this.props.match.params.slugType !== "undefined"
        ? this.props.match.params.slugType
        : "";

    this.state = {
      navigateMenu: [],
      subCatList: [],
      selectedCategoryName: "",
      selectedCategoryImg: "",
      selectedNavigation: "",
      selectedSlugType: "",
      searchSubCat: "",
      searchProVal: "",
      viewProductSlug: "",
      catNavIndex: 0,
      catslugType: "",
      catslugValue: "",
      cartTriggerFlg: "No",
      searchProResult: [],
      selectedProResult: [],
      tagList: "",
      filterTag: [],
      setFiltetTag: "No",
      productload: "Yes",
      mainSubMenu: "",
      moreSubMenu: "",
      mainMobileMenu: "",
      currentSubcat: subcatSlug,
      slugTypeN: slugTypeN,
      currentSlider: "",
      startClick: 0,
    };

    var avilablityIdTxt = cookie.load("defaultAvilablityId");
    if (avilablityIdTxt === cateringId) {
      this.props.history.push("/");
      return;
    }

    var orderOutletId = cookie.load("orderOutletId");
    if (orderOutletId === "" || orderOutletId === undefined) {
      cookie.save("orderPopuptrigger", "Yes", { path: "/" });
      this.props.history.push("/");
      return;
    }

    this.props.getSettings();
    this.loadTags();
    var availbty = cookie.load("defaultAvilablityId");
    var outltIdTxt =
      typeof cookie.load("orderOutletId") === "undefined"
        ? ""
        : cookie.load("orderOutletId");
    var zoneIdTxt =
      typeof cookie.load("orderZoneId") === "undefined"
        ? ""
        : cookie.load("orderZoneId");

    if (availbty === deliveryId && outltIdTxt !== "" && zoneIdTxt !== "") {
      this.state["delivery_outlet_id"] = outltIdTxt;
      this.props.getZoneDetail(outltIdTxt, zoneIdTxt);
    }
    this.props.getMenuNavigationList();
    // this.props.getAddonProduct(orderOutletId);
  }

  componentWillReceiveProps(nextProps) {
    let slugType =
      typeof this.props.match.params.slugType !== "undefined"
        ? this.props.match.params.slugType
        : "";
    let slugValue =
      typeof this.props.match.params.slugValue !== "undefined"
        ? this.props.match.params.slugValue
        : "";
    let proValue =
      typeof this.props.match.params.proValue !== "undefined"
        ? this.props.match.params.proValue
        : "";
    let selectedNavigation = nextProps.selectedNavigation;
    let selectedSlugType = nextProps.selectedSlugType;
    var searchSubCat = "";
    if (!isEqual(this.props.match.params, nextProps.match.params)) {
      slugType =
        typeof nextProps.match.params.slugType !== "undefined"
          ? nextProps.match.params.slugType
          : "";
      slugValue =
        typeof nextProps.match.params.slugValue !== "undefined"
          ? nextProps.match.params.slugValue
          : "";
      proValue =
        typeof nextProps.match.params.proValue !== "undefined"
          ? nextProps.match.params.proValue
          : "";
    }

    if (slugValue !== "") {
      if (slugType !== "category" && slugType !== "subcategory") {
        searchSubCat = slugValue;
        slugValue = slugType;
        slugType = "category";
        /*$('.search_result').hide();*/
        var tmpVl = "";
        $("#productsearch").val(tmpVl);
        $("#clearSearch").hide();
        $(".hsearch_sec").removeClass("open");
        $(".hsearch_trigger").removeClass("active");
        /* setTimeout(function () {
          $(window).scrollTo($("." + proValue), 100);
        }, 2000); */
      }
      selectedNavigation = slugValue;
    }

    if (slugType === "") {
      slugType = selectedSlugType;
    }

    if (
      selectedNavigation !== this.state.selectedNavigation ||
      this.state.selectedCategoryName !== nextProps.selectedCatry
    ) {
      showLoader("productlist-main-div", "class");
      var currentSubcat = "all";
      if (
        this.state.currentSubcat !== "" &&
        this.state.slugTypeN !== "category"
      ) {
        currentSubcat = this.state.currentSubcat;
      }
      var startClick = this.state.startClick;
      if (this.state.currentSubcat !== nextProps.match.params.slugValue) {
        this.setState({ startClick: 0 });
        startClick = 0;
      }

      if (slugType === "subcategory") {
        var navIndex = nextProps.navigateMenu.findIndex(
          (p) => p.pro_subcate_slug == selectedNavigation
        );
        var category_image_url = nextProps.navigateCommon.subcategory_image_url;
        var categoryNameTxt =
          Object.keys(nextProps.navigateMenu).length > 0
            ? nextProps.navigateMenu[navIndex].subcategory_name
            : nextProps.selectedCatry;
        var subCatImg =
          Object.keys(nextProps.navigateMenu).length > 0
            ? category_image_url +
              "/" +
              nextProps.navigateMenu[navIndex].pro_subcate_default_image
            : nextProps.selectedCatryImg;
        var subCatList =
          Object.keys(nextProps.navigateMenu).length > 0
            ? nextProps.navigateMenu[navIndex].subcat_list_arr.sub_result_set
            : [];
      } else {
        var navIndex = nextProps.navigateMenu.findIndex(
          (p) => p.pro_cate_slug == selectedNavigation
        );
        if (
          typeof nextProps.navigateMenu[navIndex] === undefined ||
          typeof nextProps.navigateMenu[navIndex] === "undefined"
        ) {
          if (Object.keys(nextProps.navigateMenu).length > 0) {
            var menuNav = nextProps.navigateMenu[0];
            this.props.history.push("/products/" + menuNav.pro_cate_slug);
          }
        }

        var category_image_url = nextProps.navigateCommon.category_image_url;
        var categoryNameTxt =
          Object.keys(nextProps.navigateMenu).length > 0 &&
          typeof nextProps.navigateMenu[navIndex] !== undefined &&
          typeof nextProps.navigateMenu[navIndex] !== "undefined"
            ? nextProps.navigateMenu[navIndex].category_name
            : nextProps.selectedCatry;
        var subCatImg =
          Object.keys(nextProps.navigateMenu).length > 0 &&
          typeof nextProps.navigateMenu[navIndex] !== undefined &&
          typeof nextProps.navigateMenu[navIndex] !== "undefined"
            ? nextProps.navigateMenu[navIndex].pro_cate_default_image !== null
              ? category_image_url +
                "/" +
                nextProps.navigateMenu[navIndex].pro_cate_default_image
              : ""
            : nextProps.selectedCatryImg;
        var subCatList =
          Object.keys(nextProps.navigateMenu).length > 0 &&
          typeof nextProps.navigateMenu[navIndex] !== undefined &&
          typeof nextProps.navigateMenu[navIndex] !== "undefined"
            ? nextProps.navigateMenu[navIndex].subcat_list_arr.sub_result_set
            : [];
        if (subCatList.length === 1) {
          currentSubcat = subCatList[0].pro_subcate_slug;
          this.setState({ currentSlider: 0 });
        } else {
          currentSubcat = nextProps.match.params.slugValue;
          var clickableSlide = "";
          if (subCatList.length > 0) {
            subCatList.map((item, keys) => {
              if (currentSubcat === item.pro_subcate_slug) {
                clickableSlide = keys;
              }
            });
          }
          this.setState({ currentSlider: clickableSlide });
        }
      }
      this.setState(
        {
          selectedNavigation: selectedNavigation,
          catNavIndex: navIndex,
          catslugType: slugType,
          catslugValue: slugValue,
          selectedCategoryName: categoryNameTxt,
          selectedCategoryImg: subCatImg,
          searchSubCat: searchSubCat,
          searchProVal: proValue,
          subCatList: subCatList,
          currentSubcat: currentSubcat,
        },
        function () {
          this.loadManuSubmenu();
        }
      );
    }
  }

  componentDidMount() {
    /*SCROLL FIXED PRODUCT PAGE*/
    $(document).click(function (e) {
      if (!$(e.target).is(".hsearch_trigger, .hsearch_sec, .hsearch_sec * ")) {
        if ($(".product_search_result").is(":visible")) {
          $(".product_search_result").hide();
          $("#searchKeyWord").val("");
        }
      }
    });

    $(window).scroll(function () {
      var productlist_height = $(".productlist-main-div").offset();
      if (productlist_height !== undefined) {
        var mycustomscroll = $(".productlist-main-div").offset().top - 122,
          wind_toptxt = $(window).scrollTop();

        if (wind_toptxt > mycustomscroll) {
          $(".bakery_row").addClass("catogry_row_fixed");
        } else {
          $(".bakery_row").removeClass("catogry_row_fixed");
        }
      }
    });
    $(".product_search_result").hide();
  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    }
    if (field === "view_pro_data" && value !== "") {
      this.setState(
        { viewProductSlug: value },
        function () {
          this.openProDetailPopup();
        }.bind(this)
      );
    }
    if (field === "productlist" && value !== "") {
      this.setState({
        searchProResult: value,
        selectedProResult: value,
      });
    }
    if (field === "setFiltetTag" && value !== "") {
      this.setState({
        setFiltetTag: value,
      });
    }
  };

  productFlageChange = (field, value) => {
    this.setState(
      update(this.state, { productflage: { [field]: { $set: value } } })
    );
  };

  openProDetailPopup() {
    showLoader("comboPro-" + this.state.viewProductSlug, "Idtext");
    $("#ProductDetailMdl").modal({ backdrop: "static", keyboard: false });
  }

  searchProKeyPress = (event) => {
    var value = event.target.value.toLowerCase(),
      matches = this.state.searchProResult.filter(function (item) {
        if (item.label.toLowerCase().indexOf(value) >= 0) {
          return value;
        }
      });

    $("#product_search_result").show();
    if (value === "") {
      $("#product_search_result").hide();
    }
    this.setState({ selectedProResult: matches });
  };

  /* load product search result  */
  loadProSearchList() {
    if (Object.keys(this.state.selectedProResult).length > 0) {
      return this.state.selectedProResult.map((loaddata, index) => (
        <li key={index}>
          <Link
            to={
              "/products/" +
              loaddata.cate_slug +
              "/" +
              loaddata.subcate_slug +
              "/" +
              loaddata.value
            }
            title="Product Details"
          >
            {loaddata.label}
          </Link>
        </li>
      ));
    } else {
      return <li className="no-pro-found">No Product found</li>;
    }
  }

  loadTags() {
    axios
      .get(apiUrl + "/products/product_tags?app_id=" + appId + "&status=A")
      .then((res) => {
        /* Success response */
        if (res.data.status === "ok") {
          this.displayTag(res.data.result_set);
        } else {
        }
      });
  }

  displayTag(tags) {
    let tagList = "";
    if (tags !== "") {
      var current = this;
      tagList = tags.map(function (item, index) {
        return (
          <li className="custom_checkbox" key={index}>
            <input
              type="checkbox"
              className="restaurants_tag"
              value={item.pro_tag_id}
              onChange={(e) => current.handleChange(current, e)}
            />
            <span>
              {item.pro_tag_image !== "" && (
                <img
                  src={tagImageUrl + "" + item.pro_tag_image}
                  alt={item.pro_tag_name}
                />
              )}
              {item.pro_tag_name}
            </span>
          </li>
        );
      });
    }
    this.setState({ tagList: tagList });
  }

  handleChange(section, event) {
    let filterTag = section.state.filterTag;
    if (event.target.checked === true) {
      filterTag.push(event.target.value);
    } else {
      var index = filterTag.indexOf(event.target.value);
      filterTag.splice(index, 1);
    }
    section.setState({ filterTag: filterTag, setFiltetTag: "Yes" });
  }
  clearFilter(event) {
    event.preventDefault();
    $(".restaurants_tag").prop("checked", false);
    this.setState({ filterTag: [], setFiltetTag: "Yes" });
  }

  loadManuSubmenu() {
    var mainSubMenu = "";
    var moreSubMenu = "";
    var mainMobileMenu = "";
    if (this.state.subCatList.length > 0) {
      mainSubMenu = this.state.subCatList.map((item, index) => {
        if (index <= 2) {
          return (
            <div
              className={
                this.state.currentSubcat === item.pro_subcate_slug
                  ? "menu-list-intvl active"
                  : "menu-list-intvl"
              }
              key={index}
            >
              <Link
                className="menu-title-link"
                title={item.pro_subcate_name}
                to={"#"}
                id={"subcatnav_" + item.pro_subcate_slug}
                onClick={this.loadSubcategory.bind(this, item.pro_subcate_slug)}
              >
                {item.pro_subcate_name}
              </Link>
            </div>
          );
        }
      });
      if (this.state.subCatList.length > 3) {
        moreSubMenu = this.state.subCatList.map((item, index) => {
          if (index > 2) {
            return (
              <li
                className={
                  this.state.currentSubcat === item.pro_subcate_slug
                    ? "menu-list-intvl active"
                    : "menu-list-intvl"
                }
                key={index}
              >
                <Link
                  className="menu-title-link"
                  title={item.pro_subcate_name}
                  to={"#"}
                  onClick={this.loadSubcategory.bind(
                    this,
                    item.pro_subcate_slug
                  )}
                >
                  {item.pro_subcate_name}
                </Link>
              </li>
            );
          }
        });
      }

      mainMobileMenu = this.state.subCatList.map((item, index) => {
        return (
          <div
            className={
              this.state.currentSubcat === item.pro_subcate_slug
                ? "menu-list-intvl active"
                : "menu-list-intvl"
            }
            key={index}
          >
            <Link
              className="menu-title-link"
              title={item.pro_subcate_name}
              to={"#"}
              onClick={this.loadSubcategory.bind(this, item.pro_subcate_slug)}
            >
              {item.pro_subcate_name}
            </Link>
          </div>
        );
      });
    }
    this.setState({
      mainSubMenu: mainSubMenu,
      moreSubMenu: moreSubMenu,
      mainMobileMenu: mainMobileMenu,
    });
  }
  loadSubcategory(subcat, e) {
    if (e !== undefined && typeof e !== "undefined") {
      e.preventDefault();
    }
    this.setState({ currentSubcat: subcat }, function () {
      this.loadManuSubmenu();
    });
  }

  /* Addon product Listing */
  addonProductListing() {
    var addonProductlst = this.props.addonproductlist;
    var clientProductStock = "";
    if (Object.keys(this.state.globalSettings).length > 0) {
      clientProductStock = this.state.globalSettings.client_product_stock;
    }
    if (Object.keys(addonProductlst).length > 0) {
      if (Object.keys(addonProductlst[0].subcategorie).length > 0) {
        var addonProlstOnly = addonProductlst[0].subcategorie[0].products;
        var addonCommonImg = this.props.addonproductcommon.product_image_source;
        var tagImageSource = this.props.addonproductcommon.tag_image_source;
        const Phtml = addonProlstOnly.map((product, index) => (
          <>
            <div
              className="cart-single-product"
              id={"addonproIndex-" + product.product_primary_id}
            >
              {product.product_thumbnail !== "" ? (
                <img src={addonCommonImg + "/" + product.product_thumbnail} />
              ) : (
                <img src={noimage} />
              )}

              <h3>
                {" "}
                {product.product_alias !== ""
                  ? stripslashes(product.product_alias)
                  : stripslashes(product.product_name)}
              </h3>
              <div class="product-price">
                <h3>{showPriceValue(product.product_price)}</h3>
              </div>
              <div className="products-ordernow-action">
                {product.product_stock > 0 || product.product_stock === null ? (
                  product.product_type === "1" ? (
                    <a
                      className="button order_nowdiv smiple_product_lk disbl_href_action"
                      href="/"
                      onClick={this.addToCartSimple.bind(
                        this,
                        product,
                        "initial"
                      )}
                    >
                      Add
                    </a>
                  ) : (
                    <a
                      href="/"
                      onClick={this.viewProDetail.bind(this, product)}
                      title="Product Details"
                      id={"comboPro-" + product.product_slug}
                      className="button order_nowdiv compo_product_lk"
                    >
                      Order Now
                    </a>
                  )
                ) : (
                  <a
                    className="button order_nowdiv disabled disbl_href_action"
                    href="/"
                  >
                    Sold Out
                  </a>
                )}
                <div className="addcart_row addcart_done_maindiv">
                  <div className="qty_bx">
                    <span
                      className="qty_minus"
                      onClick={this.proQtyAction.bind(
                        this,
                        product.product_primary_id,
                        "decr"
                      )}
                    >
                      -
                    </span>
                    <input
                      type="text"
                      className="proqty_input"
                      readOnly
                      value="1"
                    />
                    <span
                      className="qty_plus"
                      onClick={this.proQtyAction.bind(
                        this,
                        product.product_primary_id,
                        "incr"
                      )}
                    >
                      +
                    </span>
                  </div>
                  <button
                    className="btn btn_black"
                    onClick={this.addToCartSimple.bind(this, product, "done")}
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </>
        ));

        return Phtml;
      }
    } else {
      return false;
    }
  }

  /* add to cart */
  addToCartSimple(productDetail, actionFlg, event) {
    event.preventDefault();
    var IndexFlg = productDetail.product_primary_id;

    if (actionFlg === "initial") {
      $("#addonproIndex-" + IndexFlg).addClass("active");
      $("#addonproIndex-" + IndexFlg)
        .find(".smiple_product_lk")
        .hide();
      $("#addonproIndex-" + IndexFlg)
        .find(".addcart_done_maindiv")
        .show();
      return false;
    } else {
      showLoader("addonproIndex-" + IndexFlg, "Idtext");
      var availabilityId = cookie.load("defaultAvilablityId");
      /*var availabilityId = deliveryId;*/
      var availabilityName =
        availabilityId === deliveryId ? "Delivery" : "Pickup";
      var isAddonProduct = "No";
      var productId = productDetail.product_id;
      var customerId =
        typeof cookie.load("UserId") === "undefined"
          ? ""
          : cookie.load("UserId");
      var proqtyQty = $("#addonproIndex-" + IndexFlg)
        .find(".proqty_input")
        .val();

      var postObject = {};
      postObject = {
        app_id: appId,
        product_id: productId,
        product_qty: proqtyQty,
        product_type: 1,
        availability_id: availabilityId,
        availability_name: availabilityName,
        isAddonProduct: isAddonProduct,
        reference_id: customerId === "" ? getReferenceID() : "",
        customer_id: customerId,
      };

      Axios.post(
        apiUrlV2 + "cart/simpleCartInsert",
        qs.stringify(postObject)
      ).then((res) => {
        $("#addonproIndex-" + IndexFlg).removeClass("active");
        hideLoader("addonproIndex-" + IndexFlg, "Idtext");
        $("#addonproIndex-" + IndexFlg)
          .find(".addcart_done_maindiv")
          .hide();
        $("#addonproIndex-" + IndexFlg)
          .find(".smiple_product_lk")
          .show();
        if (res.data.status === "ok") {
          this.sateValChange("cartflg", "yes");
          removePromoCkValue();
          /* showCustomAlert("success", "Great choice! Item added to your cart."); */
          /*showCartLst();*/
          this.handleShowAlertFun(
            "success",
            "Great choice! Item added to your cart."
          );
        } else if (res.data.status === "error") {
          var errMsgtxt =
            res.data.message !== ""
              ? res.data.message
              : "Sorry! Products can`t add your cart.";
          showCustomAlert("error", errMsgtxt);
          this.handleShowAlertFun("Error", errMsgtxt);
        }

        return false;
      });
    }
  }
  proQtyAction(indxFlg, actionFlg) {
    var proqtyInput = $("#addonproIndex-" + indxFlg)
      .find(".proqty_input")
      .val();
    proqtyInput = parseInt(proqtyInput);
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }
    $("#addonproIndex-" + indxFlg)
      .find(".proqty_input")
      .val(proqtyInput);
  }
  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  render() {
    if (this.state.currentSlider !== "") {
      var settingsGallery = {
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        dotsClass: "slick-dots slick-thumb",
        initialSlide: this.state.currentSlider,
        responsive: [
          {
            initialSlide: this.state.currentSlider,
            breakpoint: 580,
            settings: {
              slidesToShow: 2,
            },
          },
        ],
      };
    }

    return (
      <div className="productpage-main-div">
        {/* Header start */}
        <Header
          cartTriggerFlg={this.state.cartTriggerFlg}
          sateValChange={this.sateValChange}
          showCatryName={this.state.selectedCategoryName}
          showCatryImage={this.state.selectedCategoryImg}
        />
        {/* Header End */}
        <div className="common-inner-blckdiv product-panner">
          <div className="common-inner-banner">
            <img src={bannerImage} alt="" />
            <p>
              <span>Promo</span> Deals
            </p>
          </div>
        </div>
        <MenuNavigation
          {...this.props}
          productState={this.state}
          sateValChange={this.sateValChange}
        />
        <section className="product-menu-listing">
          <div className="container">
            <div className="product-lhs">
              {this.state.subCatList.length > 0 && (
                <div className="menu-section-left">
                  <div className="menu-section-left-inner">
                    {this.state.subCatList.length > 1 && (
                      <div
                        className={
                          this.state.currentSubcat === "all" ||
                          typeof this.state.currentSubcat === undefined ||
                          typeof this.state.currentSubcat === "undefined"
                            ? "menu-list-intvl active"
                            : "menu-list-intvl"
                        }
                      >
                        <Link
                          className="menu-title-link"
                          title="Fragrant Rice"
                          to={"#"}
                          onClick={this.loadSubcategory.bind(this, "all")}
                          href="/products/category/fragrant-rice"
                        >
                          All
                        </Link>
                      </div>
                    )}
                    {this.state.mainSubMenu}
                  </div>
                  {this.state.moreSubMenu !== "" && (
                    <div className="more-menu">
                      <div className="more-menu-parent open_more_category_new">
                        <span className="more-menu-name">More</span>
                        <label className="open_more_category">
                          {" "}
                          <i className="fa fa-angle-double-down"></i>{" "}
                        </label>
                      </div>
                      <ul
                        className="more_categor_info"
                        style={{ display: "none" }}
                      >
                        {this.state.moreSubMenu}
                      </ul>
                    </div>
                  )}
                </div>
              )}
              {this.state.currentSlider !== "" && (
                <div className="menu-section-mobile-inner">
                  <Slider {...settingsGallery}>
                    {this.state.subCatList.length > 1 && (
                      <div
                        className={
                          this.state.currentSubcat === "all"
                            ? "menu-list-intvl active"
                            : "menu-list-intvl"
                        }
                      >
                        <Link
                          className="menu-title-link"
                          title="Fragrant Rice"
                          to={"#"}
                          onClick={this.loadSubcategory.bind(this, "all")}
                          href="/products/category/fragrant-rice"
                        >
                          All
                        </Link>
                      </div>
                    )}
                    {this.state.mainMobileMenu}
                  </Slider>
                </div>
              )}
              <ProductList
                {...this.props}
                productState={this.state}
                sateValChange={this.sateValChange}
              />
            </div>
            {$("body").width() > 980 && (
              <div className="product-rhs">
                <ul>
                  <CartSideBar
                    {...this.props}
                    headerState={this.state}
                    prpSateValChange={this.props.sateValChange}
                    pageName="products"
                  />
                </ul>
                {Object.keys(this.props.addonproductlist).length > 0 && (
                  <div className="add-extra-cart-addon">
                    <h2>You Want More?</h2>
                    <Slider {...settingsAddons}>
                      {this.addonProductListing()}
                    </Slider>
                  </div>
                )}
              </div>
            )}
          </div>
        </section>

        {/* Footer section */}
        <Footer />

        {/* <ProductDetail
          productState={this.state}
          sateValChange={this.sateValChange}
        /> */}
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var tempArr = Array();
  var menu_slug = "";
  var menu_type = "";
  var navigateRst = Array();
  var navigateCmn = Array();
  var selectedCatry = "";
  var selectedCatryImg = "";
  if (Object.keys(state.product).length > 0) {
    var tempArr = !("menuNavigation" in state.product[0])
      ? Array()
      : state.product[0].menuNavigation;
    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        navigateRst = tempArr[0].result_set;
        navigateCmn = tempArr[0].common;
        if (tempArr[0].result_set[0].menu_type == "main") {
          selectedCatry = tempArr[0].result_set[0].category_name;
          selectedCatryImg = tempArr[0].result_set[0].pro_cate_default_image;
          menu_slug = tempArr[0].result_set[0].pro_cate_slug;
          menu_type = "category";
        } else {
          selectedCatry = tempArr[0].result_set[0].subcategory_name;
          selectedCatryImg =
            navigateCmn.tempArr[0].result_set[0].pro_subcate_default_image;
          menu_slug = tempArr[0].result_set[0].pro_subcate_slug;
          menu_type = "subcategory";
        }
      }
    }
  }

  var zonedetailArr = Array();
  if (Object.keys(state.zonedetail).length > 0) {
    if (state.zonedetail[0].status === "ok") {
      zonedetailArr = state.zonedetail[0].result_set;
    }
  }
  var addonProArr = Array();
  var addonCommonArr = Array();
  if (Object.keys(state.addonproduct).length > 0) {
    if (
      state.addonproduct[0].status === "ok" &&
      Object.keys(state.addonproduct[0].result_set).length > 0
    ) {
      addonProArr = state.addonproduct[0].result_set;
      addonCommonArr = state.addonproduct[0].common;
    }
  }
  return {
    globalsettings: state.settings,
    navigateMenu: navigateRst,
    navigateCommon: navigateCmn,
    selectedCatry: selectedCatry,
    selectedNavigation: menu_slug,
    selectedSlugType: menu_type,
    zonedetails: zonedetailArr,
    addonproductlist: addonProArr,
    addonproductcommon: addonCommonArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getZoneDetail: (outletId, zoneId) => {
      dispatch({ type: GET_ZONE_DETAIL, outletId, zoneId });
    },
    getMenuNavigationList: () => {
      dispatch({ type: GET_MENU_NAVIGATION });
    },
    getAddonProduct: (outletId) => {
      dispatch({ type: GET_ADDONPRODUCT, outletId });
    },
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(Products);
