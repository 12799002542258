/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_HIGHLIGHTPROLIST, SET_HIGHLIGHTPROLIST } from "../actions";
import { apiUrl, appId, defaultOutletID } from "../components/Helpers/Config";
import Axios from "axios";
import cookie from "react-cookies";

export const watchGetHighlightproductslist = function* () {
  yield takeEvery(GET_HIGHLIGHTPROLIST, workerGetHighlightproductslist);
};

function* workerGetHighlightproductslist() {
  try {
    var orderOutletId =
      cookie.load("orderOutletId") === undefined ||
      cookie.load("orderOutletId") == ""
        ? defaultOutletID
        : cookie.load("orderOutletId");
    var UserId =
      cookie.load("UserId") === undefined || cookie.load("UserId") == ""
        ? ""
        : cookie.load("UserId");
    const uri =
      apiUrl +
      "products/highlight_products_list?app_id=" +
      appId +
      "&customer_id=" +
      UserId +
      "&outletId=" +
      orderOutletId;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_HIGHLIGHTPROLIST, value: resultArr });
  } catch (e) {
    console.log(e, "Get Highlight Products List Failed");
  }
}
